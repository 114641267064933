import { SearchClient } from 'algoliasearch'

import {
  SearchForFacetValuesResponse,
  SearchResponse,
} from '@algolia/autocomplete-preset-algolia'
import { ViewOption } from '../../constants'
import { LocalUser } from '../../hooks/use-local-user'
import {
  QueryContext,
  SearchParams,
  Index,
  Query,
  SegmentHits,
  Segment,
  RawResults,
  SegmentedHit,
  AlgoliaResults,
} from './types'

import {
  AlgoliaAnalyticsTagsRepositories,
  AlgoliaAnalyticsTagsUses,
} from './algolia-analytics-tags'
import { searchSuggestionsContextFromQueryContext } from './algolia-helpers'
import { Centre } from '@scentregroup/shared/types/contentful-v1'

// The queries we run whenever text changes, and how the results turn into dom.
// Everything here is passed to algolia except for the render method.

const centreWithDateAndViewOptionFilter = (
  centreID: string,
  viewOptionToExclude: string,
  getOffsetlessTimeInCentre: () => number
): SearchParams => ({
  filters: `centres:${centreID} AND (NOT viewOption:"${viewOptionToExclude}") AND (showAt<${getOffsetlessTimeInCentre()} AND hideAt>${getOffsetlessTimeInCentre()})`,
})

const countryWithDateAndViewOptionFilter = (
  country: string,
  viewOptionToExclude: string,
  getOffsetlessTimeInDefaultTz: () => number
): SearchParams => ({
  filters: `countries:"${country}" AND (NOT viewOption:"${viewOptionToExclude}") AND (showAt<${getOffsetlessTimeInDefaultTz()} AND hideAt>${getOffsetlessTimeInDefaultTz()})`,
})

const singleCentreWithDateFilter = (
  centreID: string,
  getOffsetlessTimeInCentre: () => number
): SearchParams => ({
  filters: `centre:${centreID} AND (showAt<${getOffsetlessTimeInCentre()} AND hideAt>${getOffsetlessTimeInCentre()})`,
})

interface CentreSegmentContext {
  country: string
  getOffsetlessTimeInDefaultTz: () => number
  centre: Centre
  getOffsetlessTimeInCentre: () => number
  user: LocalUser | undefined
}

type GetSegmentParams = (context: QueryContext) => SearchParams | null

function isCentreSectionContext(
  context: QueryContext
): context is CentreSegmentContext {
  return Boolean(context?.centre) && Boolean(context?.getOffsetlessTimeInCentre)
}

function nullIfNotCentre(
  f: (context: CentreSegmentContext) => SearchParams
): GetSegmentParams {
  return function (context) {
    return isCentreSectionContext(context) ? f(context) : null
  }
}

const basicCentreFilter = nullIfNotCentre((context: CentreSegmentContext) =>
  singleCentreWithDateFilter(
    context.centre.sys.id,
    context.getOffsetlessTimeInCentre
  )
)

const justCountryFilter = ({ country }: QueryContext): SearchParams => ({
  filters: `country:"${country}"`,
})

const viewOptionToExclude = (user: LocalUser | undefined): ViewOption =>
  user?.isLoggedIn ? ViewOption.HIDE_FROM_MEMBERS : ViewOption.MEMBERS_ONLY

const viewOptionCentreFilter = nullIfNotCentre(
  (context: CentreSegmentContext) =>
    centreWithDateAndViewOptionFilter(
      context.centre.sys.id,
      viewOptionToExclude(context.user),
      context.getOffsetlessTimeInCentre
    )
)

const viewOptionCountryFilter = (context: QueryContext): SearchParams =>
  countryWithDateAndViewOptionFilter(
    context.country,
    viewOptionToExclude(context.user),
    context.getOffsetlessTimeInDefaultTz
  )

const makeMarketplaceFilter = (params: SearchParams): GetSegmentParams =>
  function marketplaceFilter(context) {
    return context.country === 'Australia' ? params : null
  }

const searchSuggestionsFilter = (context: QueryContext): SearchParams => {
  const centreSlug = context.centre?.slug
  const isCentreAndNotStores =
    centreSlug && context.specialSearchKind !== 'stores'
  // N.B. context the searchSuggestion property we're querying here is
  // different to the QueryContext object we've been passed as a param
  const defaultSuffix = `context:'${searchSuggestionsContextFromQueryContext(
    context
  )}'`
  const centrePrefix = isCentreAndNotStores
    ? `centreSlug:'${centreSlug}' OR `
    : ''
  return { filters: `${centrePrefix}${defaultSuffix}` }
}

const onlineServicesFilter = (context: QueryContext): SearchParams =>
  context.country === 'New Zealand' ? { filters: 'isEvolvedOnly: false' } : {}

type SegmentParams = Record<Segment, GetSegmentParams>
type SegmentIndexes = Record<Segment, Index>

export const segmentParams: SegmentParams = {
  nationalStorefronts: justCountryFilter,
  stores: basicCentreFilter,
  sites: nullIfNotCentre(() => ({})),
  services: basicCentreFilter,
  localOffers: viewOptionCentreFilter,
  nationalOffers: viewOptionCountryFilter,
  events: viewOptionCentreFilter,
  localNews: viewOptionCentreFilter,
  nationalNews: viewOptionCountryFilter,
  localStories: viewOptionCentreFilter,
  nationalStories: viewOptionCountryFilter,
  centres: justCountryFilter,
  onlineServices: onlineServicesFilter,
  otherStores: justCountryFilter,
  westfieldProducts: makeMarketplaceFilter({
    removeWordsIfNoResults: 'allOptional',
  }),
  searchSuggestions: searchSuggestionsFilter,
}

export const segmentIndexes: SegmentIndexes = {
  nationalStorefronts: 'nationalStorefront',
  stores: 'store',
  sites: 'site',
  services: 'service',
  localOffers: 'offer',
  nationalOffers: 'offer',
  events: 'event',
  localNews: 'news',
  nationalNews: 'news',
  localStories: 'story',
  nationalStories: 'story',
  centres: 'centre',
  onlineServices: 'onlineService',
  otherStores: 'store',
  westfieldProducts: 'westfieldProduct',
  searchSuggestions: 'searchSuggestions',
}

export interface Clients {
  content: SearchClient
}

export const whichClient = (_segment: Segment): keyof Clients => 'content'

interface Queries {
  content: Query<Index>[]
}

const processResults = (
  segment: Segment,
  { hits, index, queryID }: RawResults
): AlgoliaResults => ({
  indexName: index ?? '',
  queryId: queryID ?? '',
  hits: hits.map(hit => ({ ...hit, segment })) as SegmentedHit[],
})

const analyticsTags = [
  AlgoliaAnalyticsTagsRepositories.WebsiteClient,
  AlgoliaAnalyticsTagsUses.SiteSearch,
]

type SegmentsByClient = { content: Segment[] }

function getSegmentsAndQueriesByClient(
  segments: Segment[],
  context: QueryContext,
  queryText: string
): {
  queries: Queries
  segmentsByClient: SegmentsByClient
} {
  const queries: Queries = { content: [] }
  const segmentsByClient: SegmentsByClient = {
    content: [],
  }
  segments.forEach(segment => {
    const params = segmentParams[segment](context)
    if (params) {
      const indexName = segmentIndexes[segment]
      queries[whichClient(segment)].push({
        query: queryText,
        params: {
          ...params,
          clickAnalytics: true,
          analyticsTags,
        },
        indexName,
      })
      segmentsByClient[whichClient(segment)].push(segment) // record the shape for the response
    }
  })
  return { segmentsByClient, queries }
}

function isSearchResponse(
  response: SearchForFacetValuesResponse | SearchResponse<unknown>
): response is SearchResponse<unknown> {
  return Boolean((response as SearchResponse<unknown>).hits)
}

export default async function querySegments(
  queryText: string,
  clients: Clients,
  context: QueryContext,
  segmentsToQuery: Segment[]
): Promise<SegmentHits> {
  const { segmentsByClient, queries } = getSegmentsAndQueriesByClient(
    segmentsToQuery,
    context,
    queryText
  )
  const [contentResponse] = await Promise.all([
    queries.content.length ? clients.content.search(queries.content) : null,
  ])
  const processedResults: SegmentHits = {}

  contentResponse?.results
    .filter(isSearchResponse)
    .forEach((raw: RawResults, i) => {
      const segment = segmentsByClient.content[i]
      processedResults[segment] = processResults(segment, raw)
    })

  return processedResults
}
