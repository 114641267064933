import { Container as ChakraContainer } from '@chakra-ui/react'
import {
  As,
  HubStyleObject,
  StandardSizes,
  StandardSpacings,
  HubResponsiveArray,
  useCssValueFromResponsiveProp,
  forwardRef,
  useBreakpointValue,
  DEFAULT_BREAKPOINT,
  HubResponsiveValue,
} from '@hub/design-system-base'
import React, { createContext } from 'react'

export interface ContainerProps {
  sx?: HubStyleObject
  className?: string
  as?: As
  maxWidth?: HubResponsiveArray<StandardSizes>
  fadeOutOverflow?: HubResponsiveValue<boolean>
  gutter: HubResponsiveArray<StandardSpacings>
}

export interface ContainerContextValues {
  maxWidth?: StandardSizes
  gutter: StandardSpacings
}

const DEFAULT_MAX_WIDTH = 'size-full'
const DEFAULT_GUTTER = 'spacing-none'

export const ContainerContext = createContext<ContainerContextValues>({
  maxWidth: DEFAULT_MAX_WIDTH,
  gutter: DEFAULT_GUTTER,
})

export const Container = forwardRef<ContainerProps, typeof ChakraContainer>(
  (
    {
      as,
      sx,
      className,
      children,
      maxWidth = DEFAULT_MAX_WIDTH,
      gutter,
      fadeOutOverflow = false,
    },
    ref
  ) => {
    const gutterForBreakpoint = useCssValueFromResponsiveProp<StandardSpacings>(
      gutter,
      'space'
    )
    const maxWidthForBreakpoint = useCssValueFromResponsiveProp<StandardSizes>(
      maxWidth,
      'sizes'
    )
    const fadeOutoverflowSafe = fadeOutOverflow ?? [false]
    const fadeOutoverflowArray = (
      Array.isArray(fadeOutoverflowSafe)
        ? fadeOutoverflowSafe
        : [fadeOutoverflowSafe]
    ) as boolean[]
    const fadeOutOverflowForBreakpoint = useBreakpointValue<boolean>(
      fadeOutoverflowArray,
      DEFAULT_BREAKPOINT
    )

    return (
      <ContainerContext.Provider
        value={{
          maxWidth: maxWidthForBreakpoint,
          gutter: gutterForBreakpoint || DEFAULT_GUTTER,
        }}
      >
        <ChakraContainer
          ref={ref}
          as={as}
          maxWidth={maxWidth}
          px={gutter}
          sx={{
            ...sx,
            ...(fadeOutOverflowForBreakpoint && {
              maskImage: `linear-gradient(
                  to right,
                  ${
                    '' /* Fully transparent at 2px -> Fully opaque at the edge of the left gutter */
                  }
                  rgba(0,0,0,0) 2px, rgba(0,0,0,1) ${gutterForBreakpoint},
                  ${
                    '' /* Fully opaque at the start of the right gutter -> Fully transparent at right edge - 2px */
                  }
                  rgba(0,0,0,1) calc(100% - ${gutterForBreakpoint}), rgba(0,0,0,0) calc(100% - 2px)
                )`,
            }),
          }}
          className={className}
        >
          {children}
        </ChakraContainer>
      </ContainerContext.Provider>
    )
  }
)

Container.displayName = 'Container'
