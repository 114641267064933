import React, { ReactElement } from 'react'
import { kebabCase } from 'lodash'
import { Box } from '@hub/box'
import { ButtonContent, LinkContent, LinkSection } from './link-section'
import { trackUniversalFooterLinkClick } from './analytics'

export interface MembershipColumnLinksPropsType {
  heading: LinkContent | ButtonContent
  links?: LinkContent[]
}

export const MembershipColumnLinks = ({
  heading,
  links,
}: MembershipColumnLinksPropsType): ReactElement => (
  <Box as="nav" aria-labelledby={kebabCase(heading.text)}>
    <LinkSection
      heading={heading}
      links={links}
      trackers={{ clicked: trackUniversalFooterLinkClick }}
    />
  </Box>
)
