import moment from 'moment'
import {
  MDate,
  TimeRange,
  TimeRangeNames,
} from '@scentregroup/shared/types/whats-on'

const noFilterString = ''
export const getTimeRangeSlug = (name: string): string =>
  (name || '').split(' ').join('-').toLowerCase()

enum DaysOfTheWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

interface TimeRangeOptions {
  range?: TimeRangeNames | null
  baseTime?: MDate | null
  selectedFilter?: string | null
}

interface TimeRangeDates {
  startsAt: MDate
  endsAt: MDate
}

const toDay = (date: MDate): number => parseInt(date.format('d'))

class InvalidTimeRange implements TimeRange {
  public startsAt = moment(0)
  public endsAt = moment(0)
  public name = TimeRangeNames.Never

  public slug(): string {
    return ''
  }

  public isValid(): boolean {
    return false
  }

  public isInRange(): boolean {
    return false
  }
}

class ValidTimeRange implements TimeRange {
  public startsAt: MDate
  public endsAt: MDate
  public name: TimeRangeNames

  public constructor({
    startsAt,
    endsAt,
    name = TimeRangeNames.Never,
  }: {
    startsAt: MDate
    endsAt: MDate
    name: TimeRangeNames
  }) {
    this.startsAt = startsAt
    this.endsAt = endsAt
    this.name = name
  }

  public slug(): string {
    return getTimeRangeSlug(this.name)
  }

  public isValid(): boolean {
    return true
  }

  public isInRange(date: MDate): boolean {
    return (
      date.valueOf() >= this.startsAt.valueOf() &&
      date.valueOf() <= this.endsAt.valueOf()
    )
  }
}

// the null option is here for non-TS consumers
const validOptions = (options: TimeRangeOptions | null): boolean =>
  Boolean(options && Boolean(options.range) && Boolean(options.baseTime))

const startOfDay = (date: MDate): MDate => moment(date).startOf('day')

const endOfDay = (date: MDate): MDate => moment(date).endOf('day')

// we need to do moment again to create a new object rather than reference the same one
const todaysTimes = (baseTime: MDate): TimeRangeDates => ({
  startsAt: moment(baseTime),
  endsAt: endOfDay(baseTime),
})

const todaysTimesFromStart = (baseTime: MDate): TimeRangeDates => ({
  startsAt: startOfDay(baseTime),
  endsAt: endOfDay(baseTime),
})

const addDays = (
  { extraStart, extraEnd }: { extraStart: number; extraEnd: number },
  timeRange: TimeRangeDates
): TimeRangeDates => ({
  startsAt: timeRange.startsAt.add(extraStart, 'days'),
  endsAt: timeRange.endsAt.add(extraEnd, 'days'),
})

const tomorrowsTimes = (baseDate: MDate): TimeRangeDates =>
  addDays({ extraStart: 1, extraEnd: 1 }, todaysTimesFromStart(baseDate))

const daysUntilFriday = (date: MDate): number => 5 - toDay(date)

const thisWeeksTimes = (
  baseDate: MDate,
  selectedFilter: string
): TimeRangeDates =>
  addDays(
    {
      extraStart: selectedFilter === 'this-week' ? 0 : 2,
      extraEnd: daysUntilFriday(baseDate),
    },
    todaysTimes(baseDate)
  )

const daysUntilStartOfWeekend = (
  date: MDate,
  selectedFilter: string
): number => {
  if (selectedFilter === 'this-weekend') {
    if (toDay(date) === DaysOfTheWeek.Sunday) {
      return -1
    }
    return 6 - toDay(date)
  } else {
    return (
      6 -
      toDay(date) +
      (toDay(date) === DaysOfTheWeek.Friday ? 1 : 0) +
      (toDay(date) === DaysOfTheWeek.Saturday ? 7 : 0) +
      (toDay(date) === DaysOfTheWeek.Sunday ? 6 : 0)
    )
  }
}

const daysUntilSunday = (date: MDate): number => {
  if (toDay(date) === DaysOfTheWeek.Sunday) {
    return 0
  } else {
    return 7 - toDay(date)
  }
}

const thisWeekendsTimes = (
  baseDate: MDate,
  selectedFilter: string
): TimeRangeDates =>
  addDays(
    {
      extraStart: daysUntilStartOfWeekend(baseDate, selectedFilter),
      extraEnd: daysUntilSunday(baseDate),
    },
    todaysTimesFromStart(baseDate)
  )

const daysUntilNextMonday = (date: MDate): number => 8 - toDay(date)
const daysUntilNextSunday = (date: MDate): number =>
  daysUntilNextMonday(date) + 6

const nextWeeksTimes = (baseDate: MDate): TimeRangeDates =>
  addDays(
    {
      extraStart: daysUntilNextMonday(baseDate),
      extraEnd: daysUntilNextSunday(baseDate),
    },
    todaysTimesFromStart(baseDate)
  )

const daysUntilMondayAfterNextWeek = (date: MDate): number => 15 - toDay(date)
const daysUntilOneMonthAfterNextWeek = (date: MDate): number =>
  daysUntilMondayAfterNextWeek(date) + 29

const upcomingTimes = (baseDate: MDate): TimeRangeDates =>
  addDays(
    {
      extraStart: daysUntilMondayAfterNextWeek(baseDate),
      extraEnd: daysUntilOneMonthAfterNextWeek(baseDate),
    },
    todaysTimesFromStart(baseDate)
  )

const invalidTimes = (): TimeRangeDates & {
  name: TimeRangeNames
  slug: string
} => ({
  startsAt: moment(1),
  endsAt: moment(0),
  name: TimeRangeNames.Never,
  slug: '',
})

const timesForRange = (
  range: TimeRangeNames,
  baseTime: MDate,
  selectedFilter: string
): TimeRangeDates & { name: TimeRangeNames; slug: string } => {
  if (range === TimeRangeNames.Today) {
    return {
      ...todaysTimes(baseTime),
      name: range,
      slug: getTimeRangeSlug(range),
    }
  } else if (range === TimeRangeNames.Tomorrow) {
    if (toDay(baseTime) === DaysOfTheWeek.Sunday) {
      return invalidTimes()
    } else {
      return {
        ...tomorrowsTimes(baseTime),
        name: range,
        slug: getTimeRangeSlug(range),
      }
    }
  } else if (range === TimeRangeNames.ThisWeek) {
    if (toDay(baseTime) === DaysOfTheWeek.Sunday) {
      return invalidTimes()
    } else {
      return {
        ...thisWeeksTimes(baseTime, selectedFilter),
        name: range,
        slug: getTimeRangeSlug(range),
      }
    }
  } else if (range === TimeRangeNames.ThisWeekend) {
    return {
      ...thisWeekendsTimes(baseTime, selectedFilter),
      name: range,
      slug: getTimeRangeSlug(range),
    }
  } else if (range === TimeRangeNames.NextWeek) {
    return {
      ...nextWeeksTimes(baseTime),
      name: range,
      slug: getTimeRangeSlug(range),
    }
  } else if (range === TimeRangeNames.Upcoming) {
    return {
      ...upcomingTimes(baseTime),
      name: range,
      slug: getTimeRangeSlug(range),
    }
  } else {
    return invalidTimes()
  }
}

export const timeRange = (options: TimeRangeOptions): TimeRange => {
  if (validOptions(options)) {
    const times = timesForRange(
      options.range as TimeRangeNames,
      options.baseTime as MDate,
      options.selectedFilter as string
    )

    if (times.startsAt.valueOf() < times.endsAt.valueOf()) {
      return new ValidTimeRange({ ...times })
    } else {
      return new InvalidTimeRange()
    }
  } else {
    return new InvalidTimeRange()
  }
}

export const rangesForDate = (
  date: MDate,
  selectedFilter: string = noFilterString
): TimeRange[] =>
  [
    timeRange({
      range: TimeRangeNames.Today,
      baseTime: date,
      selectedFilter,
    }),
    timeRange({
      range: TimeRangeNames.Tomorrow,
      baseTime: date,
      selectedFilter,
    }),
    timeRange({
      range: TimeRangeNames.ThisWeek,
      baseTime: date,
      selectedFilter,
    }),
    timeRange({
      range: TimeRangeNames.ThisWeekend,
      baseTime: date,
      selectedFilter,
    }),
    timeRange({
      range: TimeRangeNames.NextWeek,
      baseTime: date,
      selectedFilter,
    }),
    timeRange({
      range: TimeRangeNames.Upcoming,
      baseTime: date,
      selectedFilter,
    }),
  ].filter(range => range.isValid())

const stripOffset = (inputTime: string): string => {
  let [datePart, timePart] = inputTime.split('T') // eslint-disable-line prefer-const
  if (timePart) {
    timePart = timePart.replace(/Z$/, '')
  }
  // Handle time string with time offset like 2019-01-01T03:00+01:00
  return timePart
    ? `${datePart}T${timePart.replace(/[+-](?=[^[+-]*$)+.*$/, '')}`
    : inputTime
}

export const timezonelessTime = (inputTime: string): string => {
  const t = stripOffset(inputTime)
  return `${t}Z`
}

export const timezonelessDate = (inputTime: string): MDate =>
  moment(timezonelessTime(inputTime)).utc()

export const isInTimeRange =
  <T extends { endAt: MDate }>(
    inputTimeRange: TimeRange
  ): ((occurrence: T) => boolean) =>
  occurrence =>
    inputTimeRange.isInRange(occurrence.endAt)
