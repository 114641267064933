import React, { Children } from 'react'
import {
  Grid as ChakraGrid,
  GridProps as ChakraGridProps,
} from '@chakra-ui/react'
import {
  As,
  HubResponsiveValue,
  HubStyleObject,
  StandardSizes,
  StandardSpacings,
  useToken,
  forwardRef,
} from '@hub/design-system-base'
import { Box } from '@hub/box'

interface GridProps {
  as?: As
  gap?: HubResponsiveValue<StandardSpacings>
  maxWidth?: HubResponsiveValue<StandardSizes>
  sx?: HubStyleObject
  numberOfRows?: number
  role?: string
  templateColumns?: ChakraGridProps['templateColumns']
  templateRows?: ChakraGridProps['templateRows']
  autoColumns?: ChakraGridProps['autoColumns']
  autoRows?: ChakraGridProps['autoRows']
  columnGap?: ChakraGridProps['columnGap']
  rowGap?: ChakraGridProps['rowGap']
}

export const Grid = forwardRef<GridProps, typeof ChakraGrid>(
  (
    {
      as,
      maxWidth = 'size-sm',
      gap = 'spacing-none',
      numberOfRows,
      sx,
      children,
      role,
      templateColumns,
      templateRows,
      autoColumns,
      autoRows,
      columnGap,
      rowGap,
    },
    ref
  ) => {
    const [...maxWidthTokens]: string[] = useToken(
      'sizes',
      [maxWidth]
        .flat()
        .map(width => (width === null ? 'placeholder' : (width as string)))
    )

    const numberOfRowsBonusStyling = numberOfRows
      ? {
          gridAutoRows: '0',
          gridAutoColumns: '0',
          gridTemplateRows: 'repeat(2, 1fr)',
          overflow: 'hidden',
        }
      : {}

    const numberOfRowsModifiedChildrenOrPlainChildren = numberOfRows
      ? Children.map(children, child => (
          <Box sx={{ overflow: 'hidden', height: '100%', width: '100%' }}>
            {child}
          </Box>
        ))
      : children

    return (
      <ChakraGrid
        role={role}
        ref={ref}
        as={as}
        templateColumns={
          templateColumns ??
          maxWidthTokens.map(token =>
            token === 'placeholder'
              ? null
              : `repeat(auto-fit, minmax(min(${token}, 100%), 1fr))`
          )
        }
        templateRows={templateRows}
        autoColumns={autoColumns}
        autoRows={autoRows}
        gap={gap}
        columnGap={columnGap}
        rowGap={rowGap}
        sx={{
          ...numberOfRowsBonusStyling,
          ...sx,
        }}
      >
        {numberOfRowsModifiedChildrenOrPlainChildren}
      </ChakraGrid>
    )
  }
)

export { GridItem } from './grid-item'
