import React, { useMemo, useState } from 'react'
import { isDefinedAndNotNull } from '../../lib'
import { INavigationMenu, INavigationMenuWithMegaMenu } from '../header/types'

export const useLayoutNavigation = (
  giftCardsData?: INavigationMenu,
  primaryLinks?: INavigationMenuWithMegaMenu,
  secondaryLinks?: INavigationMenuWithMegaMenu
): {
  isOpenPrimaryMenu: boolean
  setIsOpenPrimaryMenu: React.Dispatch<React.SetStateAction<boolean>>
  isOpenSecondaryMenu: boolean
  setIsOpenSecondaryMenu: React.Dispatch<React.SetStateAction<boolean>>
  giftCards: INavigationMenu | undefined
  primaryMenuItems: INavigationMenuWithMegaMenu | undefined
  secondaryMenuItems: INavigationMenuWithMegaMenu | undefined
} => {
  const [isOpenPrimaryMenu, setIsOpenPrimaryMenu] = useState(false)
  const [isOpenSecondaryMenu, setIsOpenSecondaryMenu] = useState(false)

  const giftCards = useMemo(() => {
    return giftCardsData?.filter(isDefinedAndNotNull)
  }, [giftCardsData])

  return {
    isOpenPrimaryMenu,
    setIsOpenPrimaryMenu,
    isOpenSecondaryMenu,
    setIsOpenSecondaryMenu,
    giftCards,
    primaryMenuItems: primaryLinks,
    secondaryMenuItems: secondaryLinks,
  }
}
