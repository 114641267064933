import React, {
  createContext,
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { Box } from '@hub/box'
import {
  SystemStyleObject,
  forwardRef,
  useBreakpointValue,
} from '@chakra-ui/react'
import { DEFAULT_BREAKPOINT, HubResponsiveValue } from '@hub/design-system-base'
import {
  getBottomSlideStyle,
  getLeftSlideStyle,
  getRightSlideStyle,
  getTopSlideStyle,
} from './styles'
import { useRect, Rect } from './use-rect'

import type { Placement as OverlayPlacement } from './overlay'

type Placement = 'top' | 'right' | 'bottom' | 'left'

export const OverlayContentContext = createContext<{
  overlayPlacement?: OverlayPlacement
  backgroundColor?: HubResponsiveValue<string>
  scrollbarWidth: number
  containerRef?: React.RefObject<HTMLElement | null>
  clickAwayRef?: React.RefObject<HTMLElement | null>
  onOpen?: (id: symbol) => void
  onClose?: (id: symbol) => void
}>({ scrollbarWidth: 0 })

type State = {
  isOpen: boolean
  contentProps: { sx?: SystemStyleObject }
  contentContainerProps: { sx?: SystemStyleObject }
}

const zeroRect = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
}

const stateReducer = (
  _state: State | null,
  {
    placement,
    overlayPlacement,
    isOpen,
    scrollbarWidth,
    contentRect = zeroRect,
    containerRect = zeroRect,
    bodyRect = zeroRect,
  }: {
    placement: Placement
    overlayPlacement: OverlayPlacement
    isOpen: boolean
    scrollbarWidth: number
    contentRect?: Rect
    containerRect?: Rect
    bodyRect?: Rect
  }
): State => {
  if (placement === 'top') {
    const contentHeight = Math.ceil(contentRect.height)
    const css = getTopSlideStyle(isOpen, contentHeight)

    const contentProps = {
      sx: {
        ...css,
        transform: contentRect || isOpen ? css.transform : undefined,
        pointerEvents: 'none',
      },
    }
    const contentContainerProps = {
      sx: {
        top: `calc(${Math.floor(containerRect.bottom)}px - ${Math.floor(
          bodyRect.top
        )}px)`,
        left: 0,
        bottom: 0,
        width: `calc(100vw - ${scrollbarWidth}px)`,
        position: 'absolute',
        zIndex: 'menu',
        pointerEvents: 'none',
      },
    }
    return { isOpen, contentProps, contentContainerProps }
  }
  if (placement === 'bottom') {
    const contentHeight = Math.ceil(contentRect.height)
    const css = getBottomSlideStyle(isOpen, contentHeight)

    const contentProps = {
      sx: {
        ...css,
        transform: contentRect || isOpen ? css.transform : undefined,
        pointerEvents: 'none',
      },
    }
    const contentContainerProps = {
      sx: {
        left: 0,
        bottom: 0,
        width: `calc(100vw - ${scrollbarWidth}px)`,
        position: 'fixed',
        zIndex: 'menu',
        pointerEvents: 'none',
      },
    }
    return { isOpen, contentProps, contentContainerProps }
  }
  if (
    overlayPlacement === 'left' &&
    (placement === 'left' || placement === 'right')
  ) {
    const contentWidth = Math.ceil(contentRect.width)
    const css = getLeftSlideStyle(isOpen, contentWidth)

    const contentProps = {
      sx: {
        ...css,
        transform: contentRect || isOpen ? css.transform : undefined,
        pointerEvents: 'none',
      },
    }
    const contentContainerProps = {
      sx: {
        top: 0,
        left: [
          0,
          null,
          Math.ceil(
            placement === 'right' ? containerRect.width : containerRect.left
          ),
        ],
        width: 'fit-content',
        position: 'fixed',
        zIndex: 'menu',
        pointerEvents: 'none',
      },
    }
    return { isOpen, contentProps, contentContainerProps }
  }
  if (
    overlayPlacement === 'right' &&
    (placement === 'left' || placement === 'right')
  ) {
    const gutterWidth = Math.floor(bodyRect.right - containerRect.right)
    const contentWidth = Math.ceil(contentRect.width)
    const css = getRightSlideStyle(isOpen, contentWidth)

    const contentProps = {
      sx: {
        ...css,
        transform: contentRect || isOpen ? css.transform : undefined,
        pointerEvents: 'none',
      },
    }
    const contentContainerProps = {
      sx: {
        top: placement === 'left' ? 0 : 0 - bodyRect.top,
        right: [
          0,
          null,
          placement === 'right'
            ? `calc(${gutterWidth}px + ${isOpen ? 0 : -scrollbarWidth}px)`
            : containerRect.width,
        ],
        position: 'absolute',
        zIndex: 'menu',
        pointerEvents: 'none',
        overflow: 'hidden',
        width: '100vw',
        display: 'flex',
        justifyContent: 'right',
      },
    }
    return { isOpen, contentProps, contentContainerProps }
  }
  // shouldn't happen
  return {
    isOpen,
    contentProps: {
      sx: { opacity: 0 },
    },
    contentContainerProps: {
      sx: {
        position: 'absolute',
      },
    },
  }
}

/** TODO: why doesn't HubResponsiveArray<T> work instead of T[] */
export const useHubResponsiveValue: <T extends string | number>(
  value: T | (T | null)[]
) => T | null | undefined = value => {
  const arrayValue = !Array.isArray(value) ? [value] : value
  const result = useBreakpointValue(arrayValue, DEFAULT_BREAKPOINT)
  return result
}

export type OverlayContentBoxComponent = React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof Box>>
>
export type OverlayContentRender = (
  Content: OverlayContentBoxComponent
) => React.ReactNode

type OverlayContentProps = {
  isOpen: boolean
  placement?: Placement | (Placement | null)[]
  containerRef?: React.RefObject<HTMLElement | null>
  children: React.ReactNode | OverlayContentRender
} & React.ComponentProps<typeof Box>

const useNotifyContentIsOpen = (isOpen: boolean): void => {
  const { onOpen, onClose } = useContext(OverlayContentContext)
  const id = useMemo(() => Symbol(), [])
  useEffect(() => {
    if (isOpen) {
      onOpen?.(id)
    } else {
      onClose?.(id)
    }
  }, [isOpen, onClose, onOpen, id])
  useEffect(() => {
    return () => onClose?.(id)
  }, [id, onClose])
}

export const OverlayContent: React.FC<OverlayContentProps> = forwardRef(
  (
    {
      isOpen,
      placement: responsivePlacement = 'top',
      containerRef: propsContainerRef,
      children,
      ...props
    },
    ref
  ) => {
    const overlayContentContext = useContext(OverlayContentContext)
    const placement = useHubResponsiveValue(responsivePlacement) ?? 'top'
    const {
      backgroundColor,
      containerRef: contextContainerRef,
      overlayPlacement = 'top',
      scrollbarWidth,
      clickAwayRef,
    } = overlayContentContext
    const contentRef = useRef<HTMLElement>(null)
    const containerRef = propsContainerRef || contextContainerRef
    useNotifyContentIsOpen(isOpen)

    const [state, dispatchState] = useReducer(
      stateReducer,
      stateReducer(null, {
        placement,
        overlayPlacement,
        isOpen,
        scrollbarWidth,
      })
    )

    const [bodyRect, containerRect, contentRect] = useRect([
      () => document?.querySelector('body'),
      () => containerRef?.current,
      () => contentRef.current,
    ])

    useEffect(() => {
      if (!contentRect || !bodyRect) {
        return
      }

      dispatchState({
        placement,
        overlayPlacement,
        isOpen,
        scrollbarWidth,
        contentRect,
        containerRect,
        bodyRect,
      })
    }, [
      containerRef,
      isOpen,
      overlayPlacement,
      placement,
      scrollbarWidth,
      contentRect,
      containerRect,
      bodyRect,
    ])
    const OverlayContentBox: React.FC<React.ComponentProps<typeof Box>> =
      useCallback(
        props => (
          <Box
            backgroundColor={backgroundColor}
            {...props}
            sx={{
              overflow: 'auto',
              ...props.sx,
              pointerEvents: isOpen ? 'all' : 'none',
            }}
          />
        ),
        [backgroundColor, isOpen]
      )
    const renderedChildren = useMemo(() => {
      return [children].flat().map((child, key) => {
        if (isValidElement(child)) {
          return (
            <Box key={key}>
              <OverlayContentBox
                ref={clickAwayRef}
                sx={{ pointerEvents: 'none' }}
              >
                {child}
              </OverlayContentBox>
            </Box>
          )
        }
        if (typeof child === 'function') {
          return (
            <Box key={key} ref={clickAwayRef} sx={{ pointerEvents: 'none' }}>
              {child(OverlayContentBox)}
            </Box>
          )
        }
        return child
      })
    }, [OverlayContentBox, children, clickAwayRef])

    const childRef = useRef<HTMLElement>(null)
    const childContext = useMemo(
      () => ({
        ...overlayContentContext,
        containerRef: (ref || childRef) as React.RefObject<HTMLElement | null>,
      }),
      [overlayContentContext, ref]
    )

    return (
      <Box sx={state.contentContainerProps.sx}>
        <Box ref={contentRef} sx={state.contentProps.sx}>
          <Box ref={ref || childRef} {...props}>
            <OverlayContentContext.Provider value={childContext}>
              {renderedChildren}
            </OverlayContentContext.Provider>
          </Box>
        </Box>
      </Box>
    )
  }
)
