import { AccordionPanel } from '@hub/accordion'
import { Box } from '@hub/box'
import { OverlayContent } from '@hub/overlay'
import { Stack } from '@hub/stack'
import React, { useMemo } from 'react'
import {
  NavigationContextProvider,
  useNavigationContext,
} from './navigation-context'

type NavigationPanelProps = {
  width?: React.ComponentProps<typeof Box>['width']
  isOpen?: boolean
}

export const NavigationPanel: React.FC<
  React.PropsWithChildren<NavigationPanelProps>
> = ({ children, isOpen, width: propsWidth }) => {
  const context = useNavigationContext()
  const { isSubPanel, panelPlacement, navigationPlacement } = context
  const width = useMemo(() => {
    if (propsWidth !== undefined) {
      return propsWidth
    }
    if (isSubPanel) {
      return ['100vw', null, 'size-20']
    }
    return [
      '100vw',
      null,
      'calc(var(--chakra-sizes-size-3xl) + 2 * var(--chakra-space-spacing-md))',
    ]
  }, [isSubPanel, propsWidth])

  if (context.panelLayout === 'accordion') {
    return (
      <AccordionPanel>
        <Stack shouldWrapChildren={false} paddingLeft="spacing-md">
          {children}
        </Stack>
      </AccordionPanel>
    )
  }

  return (
    <OverlayContent
      isOpen={isOpen || (isOpen === undefined && Boolean(context.isOpen))}
      placement={
        panelPlacement ?? (navigationPlacement === 'left' ? 'left' : 'right')
      }
    >
      <Box
        width={width}
        height="100vh"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        <NavigationContextProvider
          value={{
            isOpen: isOpen || (isOpen === undefined && Boolean(context.isOpen)),
            panelPlacement: navigationPlacement === 'left' ? 'right' : 'left',
          }}
        >
          {children}
        </NavigationContextProvider>
      </Box>
    </OverlayContent>
  )
}
