import React from 'react'
import { List as ChakraList, ListProps } from '@chakra-ui/react'

export const List: React.FC<
  React.PropsWithChildren<
    Pick<
      ListProps,
      | 'as'
      | 'sx'
      | 'children'
      | 'spacing'
      | 'onMouseEnter'
      | 'onMouseLeave'
      | 'onClick'
      | 'className'
      | 'id'
    >
  >
> = props => <ChakraList {...props} />
