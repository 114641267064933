import {
  ReactNode,
  ComponentProps,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react'
import { has } from 'lodash'
import { SearchClient } from 'algoliasearch'
import {
  createAutocomplete,
  BaseItem as AlgoliaBaseItem,
  AutocompleteApi,
  AutocompleteSource,
  AutocompleteState,
} from '@algolia/autocomplete-core'

import { LocalUser } from '../../hooks/use-local-user'
import { ReplaceKeysValue } from '@scentregroup/shared/types'
import { Centre as CentreV1 } from '@scentregroup/shared/types/contentful-v1'

// Context types

export type SpecialSearchKind = 'centres' | 'stores' | undefined

interface BaseSearchContext {
  country: string
  centre?: CentreSearchFields
  specialSearchKind?: SpecialSearchKind
  user: LocalUser | undefined
}

export interface RenderContext extends BaseSearchContext {
  queryID?: string
  indexName?: string
  query: string
}

export interface QueryContext extends BaseSearchContext {
  getOffsetlessTimeInDefaultTz: () => number
  getOffsetlessTimeInCentre?: () => number
}

export type SearchContext = RenderContext & QueryContext

export type CentreSearchFields = Pick<
  CentreV1,
  'slug' | 'title' | 'longitude' | 'latitude' | 'sys' | 'state' | 'timeZone'
>

export type ContentfulIndex =
  | 'nationalStorefront'
  | 'store'
  | 'site'
  | 'onlineService'
  | 'service'
  | 'offer'
  | 'event'
  | 'news'
  | 'story'
  | 'centre'
  | 'productCollection'
  | 'brand'
  | 'searchSuggestions'
  | 'westfieldProduct'

export type Index = ContentfulIndex

// Expected shape of Algolia hits for various indexes:

export interface AlgoliaHit {
  objectID: string
  _highlightResult?: Record<
    string,
    {
      value: string
      matchLevel?: 'none' | 'partial' | 'full'
      fullyHighlighted?: boolean
      matchedWords?: string[]
    }
  >
}

export interface Variant {
  price?: {
    originalPrice: number
    effectivePrice: number
    discountedPrice?: number
  }
  sku: string
  onSale: boolean
}

export interface ProductFromAlgoliaHit extends AlgoliaHit {
  name: string
  shop: { id: number; name?: string; retailerId?: string }
  description?: string
  store?: string
  image?: string
  brand: { name: string; slug: string } | undefined
  variant: Variant
  variantGroupCode: string
  otherVariants: Variant[]
  category: string[]
}

export interface WestfieldProductHit extends AlgoliaHit {
  name: string
  description: string
  minPrice: number
  maxPrice: number
  image: string
  href: string
}

export interface ContentfulHit extends AlgoliaHit {
  slug: string
  img: string
  title: string
}

export interface BrandHit extends AlgoliaHit {
  title: string
  slug: string
  brandLinkUrl: string
}

export interface PageHit extends ContentfulHit {
  href: string
  isNewTab?: boolean
}

export interface ProductCollectionHit extends ContentfulHit {
  shortDescription: string
}

export interface SearchSuggestionsTransformedHit extends ContentfulHit {
  title: string
  url: string
}

export interface SearchSuggestionsBaseHit extends ContentfulHit {
  centreSlug?: string
  links: SearchSuggestionsTransformedHit[]
  context: SearchSuggestionsContext
  objectID: string
}

export function isPageHit(hit: HitType): hit is PageHit {
  return has(hit, 'isNewTab')
}

export interface StoreHit extends ContentfulHit {
  centreSlug: string
  centreName: string
}

export interface OfferHit extends ContentfulHit {
  storeTitles: string[]
}

export interface PostHit extends ContentfulHit {
  shortDescription: string
}

export interface NationalStorefrontHit extends ContentfulHit {
  retailerId: string
  shortDescription: string
  country: string
  hasMarketplaceShopId: boolean
  hasMarketplaceShopSlug: boolean
  categories: string[]
  experiences: string[]
  topics: string[]
  retailCategories: string[]
}

export type Hit<T extends Index> = T extends 'westfieldProduct'
  ? WestfieldProductHit
  : T extends 'store'
  ? StoreHit
  : T extends 'site' | 'centre' | 'onlineService'
  ? PageHit
  : T extends 'offer'
  ? OfferHit
  : T extends 'event' | 'story' | 'news'
  ? PostHit
  : T extends 'productCollection'
  ? ProductCollectionHit
  : T extends 'brand'
  ? BrandHit
  : T extends 'nationalStorefront'
  ? NationalStorefrontHit
  : ContentfulHit

export type HitType = Hit<Index>

export type SearchParams = Parameters<SearchClient['search']>[0][0]['params']

export interface Query<T extends Index> {
  indexName: T
  query: string
  params?: SearchParams
}

export interface Section<T extends Index> extends Query<T> {
  heading: string
  render: React.FC<Hit<T>>
}

export type ContentfulSections = Section<ContentfulIndex>[]
export type AllSections = Section<Index>[]

export type SearchSuggestionsContext =
  | 'AU national search'
  | 'AU stores search'
  | 'AU centre defaults'
  | 'NZ national search'
  | 'NZ stores search'
  | 'NZ centre defaults'

export interface RawResults {
  queryID?: string
  index?: string
  hits: AlgoliaHit[]
}

export interface RenderedSectionResults {
  queryId: string
  indexName: string
  heading: string
  hits: ReactNode[]
}

export interface AlgoliaApiKeys {
  contentApplicationId?: string
  contentApiKey?: string
}

export interface CoreSearchProps {
  country: string
  centre?: CentreSearchFields
}

export interface ModalSearchProps extends CoreSearchProps {
  algoliaApiKeys: AlgoliaApiKeys
  isOpen: boolean
  onClose: () => void
}

export interface EmbeddedSearchProps extends CoreSearchProps {
  showFixedQuery: string
  algoliaResults?: SegmentHits
}

export function isEmbeddedSearchProps(
  props: SearchProps
): props is EmbeddedSearchProps {
  return typeof (props as EmbeddedSearchProps).showFixedQuery === 'string'
}

export type SearchProps = ModalSearchProps | EmbeddedSearchProps

export enum Segment {
  'stores' = 'stores',
  'nationalStorefronts' = 'nationalStorefronts',
  'sites' = 'sites',
  'services' = 'services',
  'localOffers' = 'localOffers',
  'nationalOffers' = 'nationalOffers',
  'events' = 'events',
  'localNews' = 'localNews',
  'nationalNews' = 'nationalNews',
  'localStories' = 'localStories',
  'nationalStories' = 'nationalStories',
  'centres' = 'centres',
  'onlineServices' = 'onlineServices',
  'otherStores' = 'otherStores',
  'westfieldProducts' = 'westfieldProducts',
  'searchSuggestions' = 'searchSuggestions',
}

export function isSegment(maybeSegment: any): maybeSegment is Segment {
  return maybeSegment in Segment
}

export function assertSegment(maybeSegment: any): Segment {
  if (isSegment(maybeSegment)) {
    return maybeSegment
  } else {
    throw new Error(`Unknown segment ${maybeSegment}`)
  }
}

export type IndexForSegment<S extends Segment> = S extends Segment.stores
  ? 'store'
  : S extends Segment.nationalStorefronts
  ? 'nationalStorefront'
  : S extends Segment.sites
  ? 'site'
  : S extends Segment.services
  ? 'service'
  : S extends Segment.localOffers
  ? 'offer'
  : S extends Segment.nationalOffers
  ? 'offer'
  : S extends Segment.events
  ? 'event'
  : S extends Segment.localNews
  ? 'news'
  : S extends Segment.nationalNews
  ? 'news'
  : S extends Segment.localStories
  ? 'story'
  : S extends Segment.nationalStories
  ? 'story'
  : S extends Segment.centres
  ? 'centre'
  : S extends Segment.onlineServices
  ? 'onlineService'
  : S extends Segment.otherStores
  ? 'store'
  : S extends Segment.westfieldProducts
  ? 'westfieldProduct'
  : S extends Segment.searchSuggestions
  ? 'searchSuggestions'
  : never

type HitForSegment<S extends Segment> = S extends Segment.searchSuggestions
  ? SearchSuggestionsTransformedHit
  : IndexForSegment<S> extends never
  ? AlgoliaHit
  : Hit<IndexForSegment<S>>

type HitWithSegment<S extends Segment> = { segment: S } & HitForSegment<S>

// Unfortunately we need to do this rather than just
// a ternary to get the discriminated union business
// working
export type SegmentedHit =
  | HitWithSegment<Segment.stores>
  | HitWithSegment<Segment.nationalStorefronts>
  | HitWithSegment<Segment.sites>
  | HitWithSegment<Segment.services>
  | HitWithSegment<Segment.localOffers>
  | HitWithSegment<Segment.nationalOffers>
  | HitWithSegment<Segment.events>
  | HitWithSegment<Segment.localNews>
  | HitWithSegment<Segment.nationalNews>
  | HitWithSegment<Segment.localStories>
  | HitWithSegment<Segment.nationalStories>
  | HitWithSegment<Segment.centres>
  | HitWithSegment<Segment.onlineServices>
  | HitWithSegment<Segment.otherStores>
  | HitWithSegment<Segment.westfieldProducts>
  | HitWithSegment<Segment.searchSuggestions>

export type NonProductSegmentedHit = Exclude<
  SegmentedHit,
  HitWithSegment<Segment.westfieldProducts>
>

export type AlgoliaResults = {
  queryId: string | null
  indexName: string
  hits: SegmentedHit[]
}
export type SegmentHits = Partial<Record<Segment, AlgoliaResults>>

// Render types

export type OnResultClick = (hit: SegmentedHit) => void

export interface RenderProps {
  context: RenderContext
  onResultClick: OnResultClick
  index: number
}

export interface SegmentRenderProps<S extends Segment> extends RenderProps {
  hit: HitWithSegment<S>
}

export type SegmentRender<S extends Segment> = React.FC<SegmentRenderProps<S>>

export interface GroupAndRenderSegmentsArgs {
  segmentResults: SegmentHits
  context: RenderContext
  onResultClickHeading: (
    heading: string,
    hit: Parameters<OnResultClick>[0]
  ) => void
}

export interface GroupedHit {
  hit: SegmentedHit
  onResultClick: OnResultClick
  context: RenderContext
}

export interface HitsGroup {
  groupedHits: GroupedHit[]
  heading: string
  queryId: string
  indexName: string
}

export interface Clients {
  content: SearchClient
}

export interface DoQueryAlgoliaArgs {
  query: string
  clients?: Clients
  context: QueryContext
}

export type UrlGetter<S extends Segment> = (
  hit: HitWithSegment<S>,
  context: RenderContext
) => string

// Autocomplete types

// To be compatible with the BaseItem type that all the Algolia
export type BaseItem = SegmentedHit & AlgoliaBaseItem

export interface Elements {
  inputElement: HTMLInputElement | null
  formElement: HTMLFormElement | null
  panelElement: HTMLDivElement | null
  seeAllElement: HTMLButtonElement | null
  itemElements: HTMLAnchorElement[] | null
}

export interface RefProps {
  inputRef: RefObject<HTMLInputElement>
  formRef: RefObject<HTMLFormElement>
  panelRef: RefObject<HTMLDivElement>
  seeAllRef: RefObject<HTMLButtonElement>
  itemsRef: RefObject<HTMLAnchorElement[]>
}

// Algola's Event types for onClick handlers and the like don't
// match what React expects. So we have to replace the types of
// some of the keysf of AutocompleteApi leaving as much intact as
// we can

type AutoReplacingInput = ReplaceKeysValue<
  AutocompleteApi<BaseItem>,
  'getInputProps',
  (elems: Elements) => Omit<ComponentProps<'input'>, 'size'>
>

// Not sure if there's a more readable way to sequentially rework
// a type like this...

type AutoReplacingItem = ReplaceKeysValue<
  AutoReplacingInput,
  'getItemProps',
  (
    elems: Elements & { item: BaseItem; source: AutocompleteSource<BaseItem> }
  ) => Omit<ComponentProps<'ul'>, 'size'>
>

type AutoReplacingForm = ReplaceKeysValue<
  AutoReplacingItem,
  'getFormProps',
  (elems: Elements) => Omit<ComponentProps<'form'>, 'size'>
>

export type Auto = AutoReplacingForm

// Trick to get types out of a generic function
export const createAutocompleteMyType = createAutocomplete<BaseItem>

export type CreateAutocomplete = typeof createAutocompleteMyType

export type AutoState = AutocompleteState<BaseItem>
export type MaybeState = Partial<AutoState>
export type ResetState = () => void
export type CreateAutoParam = Required<Parameters<CreateAutocomplete>[0]>
export type OnStateChange = CreateAutoParam['onStateChange']
export interface AutocompleteWithMaybeState {
  autocomplete: AutocompleteApi<BaseItem>
  autocompleteState: MaybeState
  resetState: ResetState
  setNonDebouncedQuery: Dispatch<SetStateAction<string>>
}
