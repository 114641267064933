import React from 'react'
import { Box } from '@hub/box'
import { useLocalUser } from '../../hooks/use-local-user'
import ClientOnly from '../client-only'
import { EmbeddedSearchProps } from './types'
import SearchResults from './search-results'
import { processResults } from './algolia-helpers'

export const EmbeddedSearch: React.FC<
  React.PropsWithChildren<EmbeddedSearchProps>
> = props => {
  const { country, centre, showFixedQuery, algoliaResults } = props
  const user = useLocalUser()
  const baseContext = {
    user,
    centre,
    country,
    query: showFixedQuery,
  }
  const { results, showNoResults } = processResults(
    showFixedQuery,
    baseContext,
    algoliaResults || {}
  )

  return (
    <Box
      sx={{
        top: 'size-none',
        left: 'size-none',
        width: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        padding: 'spacing-none',
        alignItems: 'center',
        margin: 'spacing-none',
      }}
    >
      <ClientOnly>
        <SearchResults
          context={baseContext}
          isLoading={false}
          showNoResults={showNoResults}
          results={results}
          showFixedQuery={showFixedQuery}
        />
      </ClientOnly>
    </Box>
  )
}

export default EmbeddedSearch
