import React, { useState } from 'react'
import { Text } from '@hub/text'
import { H4 } from '@hub/heading'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@hub/input'
import { ClearInvertedSmallIcon, SearchMediumIcon } from '@hub/icon'
import { IconButton } from '@hub/button'
import {
  FilteredCentreList,
  CentreMenuLink,
  CentreListItem,
} from '@scentregroup/shared/hub-components/filtered-centre-list'

type FindCentreMenuProps = {
  country: string
  items: CentreListItem[]
  onClickLink?: (event: React.MouseEvent, path: CentreMenuLink[]) => void
}

export const FindCentreMenu: React.FC<FindCentreMenuProps> = ({
  country,
  items,
  onClickLink,
}) => {
  const [keyword, setKeyword] = useState<string>('')
  return (
    <>
      <H4 as={Text} sx={{ paddingBottom: 'spacing-md' }}>
        Find a centre
      </H4>
      <InputGroup
        backgroundColor="surfaceTertiary"
        borderStyle="solid"
        borderWidth={1}
        borderColor="borderSecondary"
        color="textSecondary"
        height="size-8"
        paddingTop="spacing-xs"
      >
        <Input
          value={keyword}
          onChange={event => setKeyword(event.target.value)}
          placeholder="Search by centre name"
          sx={{
            border: '0',
            borderColor: 'borderSecondary',
          }}
        />
        <InputLeftElement marginTop="spacing-xs">
          <SearchMediumIcon boxSize="size-icon-sm" />
        </InputLeftElement>
        <InputRightElement marginTop="spacing-xs">
          <IconButton
            variant="transparent"
            onClick={() => setKeyword('')}
            aria-label="Clear search text"
            boxSize="size-icon-sm"
            colorScheme={'licorice'}
            icon={
              <ClearInvertedSmallIcon
                sx={{
                  color: 'licorice',
                  visibility: keyword === '' ? 'hidden' : 'visible',
                }}
              />
            }
          ></IconButton>
        </InputRightElement>
      </InputGroup>
      <FilteredCentreList
        query={keyword}
        items={items}
        onClick={onClickLink}
        country={country}
      />
    </>
  )
}
