import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@hub/box'
import { Portal } from '@hub/portal'
import { Stack } from '@hub/stack'
import { useToken, StandardColors } from '@hub/design-system-base'
import { Overlay, getScrollbarWidth } from '@hub/overlay'
import { SearchContext, Auto, AutocompleteWithMaybeState } from '../types'
import { HeaderTopStrip } from '../../../hub-components/header/header-top-strip'
import SearchForm from './search-form'
import limitResults from './limit-results'
import useRefsAndCursor from './use-refs-and-cursor'

const ROWS_DISPLAY_LIMIT = 15

type Props = {
  placeholder?: string
  baseContext: Omit<SearchContext, 'getOffsetlessTimeInDefaultTz' | 'query'>
  onClickClose: () => void
  autocompleteWithState: AutocompleteWithMaybeState
  isOpen: boolean
  backgroundColor: StandardColors
}

const SearchModalAutocomplete: React.FC<React.PropsWithChildren<Props>> = ({
  placeholder,
  baseContext,
  onClickClose,
  autocompleteWithState,
  isOpen,
  backgroundColor,
}) => {
  const {
    autocomplete: baseAutocomplete,
    autocompleteState,
    resetState,
    setNonDebouncedQuery,
  } = autocompleteWithState
  // see comment in ../types.ts for this cast
  const autocomplete = baseAutocomplete as unknown as Auto
  const { collections, query } = autocompleteState
  const refsAndCursor = useRefsAndCursor(query, collections)
  const collectionsToDisplay = limitResults(
    collections ?? [],
    ROWS_DISPLAY_LIMIT
  )
  const reinitialiseQuery = (): void => {
    const { query } = autocompleteState
    if (query) {
      autocomplete.setQuery(query)
    }
  }

  const { resetCursor } = refsAndCursor
  const [isFocusOnInput, setIsFocusOnInput] = useState(false)

  useEffect(() => {
    const inputElement = refsAndCursor.autocompleteRefs.getElems().inputElement

    if (isOpen && inputElement && !isFocusOnInput) {
      inputElement.focus()
      setIsFocusOnInput(true)
    }
  }, [isOpen, refsAndCursor, isFocusOnInput])

  // On close, reset the cursor to top of search list
  useEffect(() => {
    if (!isOpen && resetCursor) {
      setIsFocusOnInput(false)
      resetCursor()
    }
  }, [isOpen, resetCursor, setIsFocusOnInput])

  const [scrollbarWidth, setScrollbarWidth] = useState(0)
  useEffect(() => {
    if (!isOpen) {
      setScrollbarWidth(getScrollbarWidth())
    }
  }, [isOpen])

  const containerRef = useRef<HTMLElement | null>(null)

  const modalZindex = useToken('zIndices', 'modal')
  const renderContext = { ...baseContext, query: query ?? '' }
  return isOpen ? (
    <>
      <Box
        ref={containerRef}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <Portal>
        <Stack
          sx={{
            top: 0,
            left: 0,
            width: '100vw',
            position: 'fixed',
            zIndex: modalZindex,
          }}
        >
          <HeaderTopStrip
            sx={{
              paddingRight: [null, null, scrollbarWidth],
              display: ['none', 'none', 'block'],
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexShrink: 0,
              width: '100%',
              height: ['100vh', '100vh', 'size-12'],
              backgroundColor,
              paddingRight: [null, null, scrollbarWidth],
            }}
          >
            <SearchForm
              {...{
                backgroundColor,
                refsAndCursor,
                onClickClose,
                autocomplete,
                placeholder,
                resetState,
                collections: collectionsToDisplay,
                context: renderContext,
                reinitialiseQuery,
                setNonDebouncedQuery,
              }}
            />
          </Box>
        </Stack>
      </Portal>
      <Overlay
        isOpen={isOpen}
        trigger="click"
        onClose={onClickClose}
        containerRef={containerRef}
        backgroundColor={backgroundColor}
      />
    </>
  ) : null
}

export default SearchModalAutocomplete
