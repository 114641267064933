import { Box } from '@chakra-ui/react'
import { List, ListItem } from '@hub/list'
import React, {
  Children,
  isValidElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DEFAULT_BREAKPOINT, useBreakpointValue } from '@hub/design-system-base'

import {
  NavigationContextProvider,
  useNavigationContext,
} from './navigation-context'

type NavigationBarProps = {
  data?: unknown
  onMouseEnter?: React.MouseEventHandler
}

type Rect = ReturnType<HTMLElement['getBoundingClientRect']>

// See https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
// for where the various rect coordinates are
function isMovedToOverlay(
  { left, right, top, bottom }: Rect,
  { clientX, clientY }: Pick<React.MouseEvent, 'clientX' | 'clientY'>,
  navPlacement: 'left' | 'right' | 'below' | undefined
): boolean {
  if (navPlacement === 'left') {
    return clientX >= right && clientY >= top && clientY <= bottom
  } else if (navPlacement === 'right') {
    return clientX <= left && clientY >= top && clientY <= bottom
  } else if (navPlacement === 'below') {
    return clientY >= bottom && clientX >= left && clientX <= right
  } else {
    // navPlacement === undefined in this case
    // However it should always be provided by the top
    // level <Navigation> component, I think the types
    // need tightening
    return false
  }
}

export const NavigationBar: React.FC<
  React.PropsWithChildren<NavigationBarProps>
> = ({ children, onMouseEnter }) => {
  const {
    overlayContentRef,
    trigger,
    activeChild: activeBar,
    registerChild,
    navigationPlacement: navPlacement,
  } = useNavigationContext()
  const [activeChild, setActiveChild] = useState<number | null>(null)
  const symbol = useRef(Symbol())
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(activeBar === symbol.current)
  }, [activeBar])

  useEffect(() => {
    registerChild?.(symbol.current, activeChild !== null)
  }, [activeChild, registerChild])
  const isMobile =
    useBreakpointValue([true, true, false], DEFAULT_BREAKPOINT) ?? false

  const childrenRefs = useRef<HTMLDivElement[]>([])
  const items = useMemo(
    () =>
      Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return child
        }
        const ref: React.Ref<HTMLDivElement> = el => {
          if (el) {
            childrenRefs.current[index] = el
          }
        }
        return (
          <NavigationContextProvider
            value={{
              isBar: true,
              overlayContentRef,
              isOpen: isOpen && index === activeChild,
              onOpen() {
                setActiveChild(index)
                registerChild?.(symbol.current, true)
              },
              onClose() {
                registerChild?.(symbol.current, false)
                setActiveChild(null)
              },
              onMouseEnter() {
                if (trigger === 'hover' || activeBar !== undefined) {
                  setActiveChild(index)
                  registerChild?.(symbol.current, true)
                }
              },
              onMouseLeave(event) {
                const rect =
                  childrenRefs.current[index]?.getBoundingClientRect()
                const isOverlay =
                  rect && isMovedToOverlay(rect, event, navPlacement)
                const noHoverNoBar =
                  trigger !== 'hover' && activeBar === undefined
                if (isMobile || isOverlay || noHoverNoBar) {
                  return
                }
                registerChild?.(symbol.current, false)
                setActiveChild(null)
              },
            }}
          >
            <Box ref={ref}>{child}</Box>
          </NavigationContextProvider>
        )
      }),
    [
      activeBar,
      activeChild,
      children,
      isOpen,
      overlayContentRef,
      registerChild,
      trigger,
      navPlacement,
      isMobile,
    ]
  )

  return (
    <List
      sx={{ direction: 'row', display: 'flex', alignItems: 'center' }}
      onMouseEnter={onMouseEnter}
    >
      {items?.map(
        (item, index) => item && <ListItem key={index}>{item}</ListItem>
      )}
    </List>
  )
}
