import React from 'react'
import { Box } from '@hub/box'
import { HubStyleObject } from '@hub/design-system-base'
import { CloudinaryImage } from '../../hub-components/cloudinary-image'
import { SGPageTemplateImage } from '../../types/page-templates'

type Ratio = 'square' | 'standard'

interface ImageProps {
  sx?: HubStyleObject
  className?: string
  dataTestId?: string
  ratio: Ratio
  imageUrl?: string
  alt?: string
  bgSize?: 'cover' | 'contain'
}

const Placeholder: React.FC<{ ratio: Ratio }> = ({ ratio }) => (
  <Box
    height={'size-none'}
    bgColor={'surfaceBrandSecondary'}
    paddingBottom={ratio === 'square' ? '100%' : '66.67%'}
  />
)

const bgSizeToResizeMode = (
  bgSize?: 'cover' | 'contain'
): 'pad' | 'crop-face' => (bgSize === 'contain' ? 'pad' : 'crop-face')

const oldRatioToNew = (ratio: Ratio): string =>
  ratio === 'square' ? '1' : '2/3'

const ActualImage: React.FC<{
  ratio: Ratio
  imageUrl: string
  alt?: string
  bgSize?: 'cover' | 'contain'
}> = ({ imageUrl, ratio, alt, bgSize }) => {
  const imageSetOrImage: SGPageTemplateImage = {
    url: imageUrl,
    alt,
    type: ratio === 'square' ? 'SQUARE' : 'WIDE',
  }
  return (
    <CloudinaryImage
      imageSetOrImage={imageSetOrImage}
      ratio={oldRatioToNew(ratio)}
      resizeMode={bgSizeToResizeMode(bgSize)}
    />
  )
}

const Image: React.FC<ImageProps> = ({
  sx,
  className,
  dataTestId,
  imageUrl,
  ratio,
  alt = '',
  bgSize,
}) => (
  <Box sx={sx} className={className} data-test-id={dataTestId}>
    {imageUrl ? (
      <ActualImage
        imageUrl={imageUrl}
        ratio={ratio}
        alt={alt}
        bgSize={bgSize}
      />
    ) : (
      <Placeholder ratio={ratio} />
    )}
  </Box>
)

export default Image
