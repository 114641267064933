//
// This file is auto generated. See ./README.md for details
//
import React from 'react'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import {
  HubResponsiveValue,
  StandardIconSizes,
  HubStyleObject,
  forwardRef,
  IconColors,
} from '@hub/design-system-base'
import { default as UserMediumSVG } from './assets/medium/user.inline.svg'

interface UserMediumIconProps {
  boxSize?: HubResponsiveValue<StandardIconSizes>
  sx?: HubStyleObject
  ariaLabel?: string
  color?: IconColors
}

export const UserMediumIcon: React.FC<
  React.PropsWithChildren<UserMediumIconProps>
> = forwardRef<UserMediumIconProps, typeof ChakraIcon>(
  ({ boxSize = 'size-icon-sm', sx, ariaLabel, color }, ref) => (
    <ChakraIcon
      ref={ref}
      boxSize={boxSize}
      aria-label={ariaLabel}
      as={UserMediumSVG}
      sx={sx}
      color={color}
    />
  )
)
