import React from 'react'
import { Stack } from '@chakra-ui/react'
import { OverlayContent } from '@hub/overlay'
import { useRect } from '@hub/overlay/src/use-rect'
import { Portal } from '@hub/portal'
import { Box } from '@hub/box'
import { IconButton } from '@hub/button'
import { CrossMediumIcon } from '@hub/icon'
import { H4 } from '@hub/heading'
import { useNavigationContext } from './navigation-context'

export const NavigationMenuList: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { width, height, isMenu, isOpen, onClose, buttonRef } =
    useNavigationContext()

  const [buttonRect, bodyRect] = useRect([
    () => buttonRef?.current,
    () => document.body,
  ])

  if (isMenu) {
    return (
      <Portal>
        <Box
          sx={{
            position: ['absolute', null, 'static'],
            top: [`-${buttonRect?.bottom}px`, null, 'initial'],
            left: [0, null, 'initial'],
            width: ['100vw', null, 'auto'],
            height: ['100dvh', null, 'auto'],
            pointerEvents: 'none',
          }}
        >
          <OverlayContent
            isOpen={isOpen ?? false}
            placement={['top', null, 'top']}
          >
            {OverlayContentBox => (
              <OverlayContentBox
                sx={{
                  position: ['relative', null, 'relative'],
                  width: ['100vw', null, 'fit-content'],
                  left: [0, null, buttonRect?.left || 0],
                  bottom: [0, null, undefined],
                  minWidth: [
                    undefined,
                    undefined,
                    buttonRect?.width || undefined,
                  ],
                  maxHeight: [
                    '100vh',
                    null,
                    `calc(100vh - ${buttonRect?.bottom ?? 0}px - ${
                      bodyRect?.top ?? 0
                    }px)`,
                  ],
                  minHeight: ['100vh', null, 'auto'],
                  overflowY: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: ['flex', null, 'none'],
                    flexGrow: 0,
                    justifyContent: 'end',
                  }}
                >
                  <IconButton
                    variant="transparent"
                    colorScheme="licorice"
                    size="icon-lg"
                    icon={<CrossMediumIcon boxSize="size-icon-md" />}
                    onClick={() => onClose?.()}
                    aria-label={'close menu'}
                  />
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    scrollBehavior: 'smooth',
                  }}
                >
                  <Box
                    paddingY="spacing-md"
                    paddingX={['spacing-mobile-margin', 'spacing-md']}
                  >
                    <Box
                      sx={{
                        height: 'size-8',
                        alignItems: 'center',
                        display: ['flex', null, 'none'],
                      }}
                    >
                      <H4>{buttonRef?.current?.textContent}</H4>
                    </Box>
                    <Box sx={{ marginLeft: ['spacing-lg', null, '0'] }}>
                      {children}
                    </Box>
                  </Box>
                </Box>
              </OverlayContentBox>
            )}
          </OverlayContent>
        </Box>
      </Portal>
    )
  }
  return (
    <Stack width={width} height={height}>
      {children}
    </Stack>
  )
}
