import React, { useMemo } from 'react'
import { groupBy } from 'lodash'
import { Box } from '@hub/box'
import { Stack } from '@hub/stack'
import { Text } from '@hub/text'
import { LocationDropPinMediumIcon } from '@hub/icon'
import { H4, H5 } from '@hub/heading'
import { MenuButton, MenuLink } from '@hub/navigation'
import { Spinner } from '@hub/spinner'
import { Centre } from '../../centres'
import { isDefinedAndNotNull } from '@scentregroup/shared/lib'
import { useCentreFilter } from '@scentregroup/shared/hooks/use-centre-filter'
import { useNearestCentres } from '@scentregroup/shared/hooks/use-nearest-centres'

export type CentreMenuLink = {
  label: string
  url: string
}

export type FilteredCentreListVariant = 'link' | 'select'

export type CentreListItem = CentreMenuLink & { centre: Centre }

const useCentresByState = (
  items: CentreListItem[],
  keyword: string
): [string, CentreMenuLink[]][] => {
  const linkedCentres = items.map(({ centre, ...navLink }) => ({
    ...centre,
    ...navLink,
  }))
  const centreFilter = useCentreFilter(linkedCentres)
  const filtered = centreFilter(keyword)
  return useMemo(() => Object.entries(groupBy(filtered, 'state')), [filtered])
}

const listItemSx = {
  paddingY: 'spacing-sm',
  height: 'size-7',
  minHeight: 'size-7',
}

export const CentreList: React.FC<{
  children: CentreMenuLink[]
  variant: FilteredCentreListVariant
  onClick?: (event: React.MouseEvent, path: CentreMenuLink[]) => void
}> = ({ children, onClick: onClick, variant }) => {
  const props = {
    size: 'md',
    leftIcon: <LocationDropPinMediumIcon boxSize={'size-icon-sm'} />,
    sx: listItemSx,
  }
  return (
    <Stack shouldWrapChildren={false}>
      {children.map((item, key) =>
        variant === 'link' ? (
          <MenuLink
            key={key}
            href={item.url}
            onClick={event => onClick?.(event, [item])}
            {...props}
          >
            {item.label}
          </MenuLink>
        ) : (
          <MenuButton
            name={item.label}
            key={key}
            onClick={event => onClick?.(event, [item])}
            {...props}
          >
            {item.label}
          </MenuButton>
        )
      )}
    </Stack>
  )
}

interface FilteredCentreListProps {
  query: string
  items: CentreListItem[]
  onClick?: (event: React.MouseEvent, path: CentreMenuLink[]) => void
  country?: string
  heading?: string
  variant?: FilteredCentreListVariant
}

export const FilteredCentreList: React.FC<FilteredCentreListProps> = ({
  query,
  items,
  onClick,
  country,
  heading,
  variant = 'link',
}) => {
  const centres = useMemo(() => items.map(({ centre }) => centre), [items])
  const centresByState = useCentresByState(items, query)
  const [nearestCentres, getSuggestedCentresNearMe] = useNearestCentres(centres)

  const suggestedCentres = useMemo(
    () =>
      nearestCentres.suggestedCentres
        .map(suggestedCentre =>
          items.find(({ centre }) => centre.slug === suggestedCentre.slug)
        )
        .filter(isDefinedAndNotNull),
    [items, nearestCentres.suggestedCentres]
  )

  return (
    <>
      {heading && (
        <H4
          sx={{
            paddingTop: 'spacing-lg',
          }}
        >
          {heading}
        </H4>
      )}
      <MenuButton
        size="md"
        rightIcon={nearestCentres.loadingLocation ? <Spinner /> : undefined}
        onClick={getSuggestedCentresNearMe}
        sx={{ paddingY: 'spacing-sm', marginBottom: 'spacing-md' }}
      >
        <Text sx={{ textDecoration: 'underline' }}>
          Or use your current location
        </Text>
      </MenuButton>
      <Stack
        direction="column"
        shouldWrapChildren={false}
        sx={{ gap: 'spacing-sm' }}
      >
        {suggestedCentres?.length > 0 && (
          <Box>
            <H5 as={Text} sx={{ marginBottom: 'spacing-sm' }}>
              Suggested
            </H5>
            <CentreList onClick={onClick} variant={variant}>
              {suggestedCentres}
            </CentreList>
          </Box>
        )}
        {query === '' && country && variant === 'link' && (
          <Box>
            <H5 as={Text} sx={{ marginBottom: 'spacing-sm' }}>
              Country
            </H5>
            <MenuLink size="md" href="/" sx={listItemSx}>
              {country}
            </MenuLink>
          </Box>
        )}
        {centresByState.map(([state, centres]) => (
          <Box key={state}>
            <H5 as={Text} sx={{ marginBottom: 'spacing-sm' }}>
              {state}
            </H5>
            <CentreList onClick={onClick} variant={variant}>
              {centres}
            </CentreList>
          </Box>
        ))}
        {centresByState.length === 0 && <Text>No centres matched</Text>}
      </Stack>
    </>
  )
}
