import { useMemo } from 'react'

export const useLayoutLogo = (
  centre: { slug: string; title: string } | undefined,
  variant: string | undefined
): { logoLink: string; logoLabel: string; logoSlug: string | undefined } => {
  return useMemo(() => {
    const logoLink = (() => {
      if (centre) {
        return `/${centre.slug}`
      }
      if (variant === 'gift-cards') {
        return '/gift-cards'
      }
      return '/'
    })()
    const logoLabel = (() => {
      if (centre) {
        return `Explore Westfield ${centre.title}`
      }
      if (variant === 'gift-cards') {
        return 'Westfield Gift Cards'
      }
      return 'Explore Westfield'
    })()
    const logoSlug = (() => {
      if (centre) {
        return centre.slug
      }
      if (variant == 'gift-cards') {
        return 'gift-cards'
      }
      return undefined
    })()
    return { logoLink, logoLabel, logoSlug }
  }, [centre, variant])
}
