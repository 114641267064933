import { AccordionItem } from '@hub/accordion'
import React, {
  Children,
  Fragment,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { NavigationButton } from './navigation-button'
import {
  NavigationContextProvider,
  useNavigationContext,
} from './navigation-context'
import { NavigationPanel } from './navigation-panel'
import { useManagedChildren } from './use-managed-children'

export const NavigationPanelMenu: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const context = useNavigationContext()
  const { panelLayout, onClose } = context
  const parentRegisterChild = context.registerChild

  const [activeChild, registerChild] = useManagedChildren()

  const [isOpen, setIsOpen] = useState(false)
  const symbol = useRef(Symbol())
  useEffect(() => {
    parentRegisterChild?.(symbol.current, isOpen)
  }, [isOpen, parentRegisterChild])
  useEffect(() => {
    if (context.activeChild !== symbol.current) {
      setIsOpen(false)
    }
  }, [context.activeChild])
  const handleClose = useCallback(
    (recursive?: boolean) => {
      setIsOpen(false)
      if (recursive) {
        onClose?.(recursive)
      }
    },
    [onClose]
  )
  const items = useMemo(
    () =>
      Children.map(children, child => {
        if (!isValidElement(child)) {
          return child
        }
        if (child.type === NavigationButton) {
          return (
            <NavigationContextProvider
              value={{
                activeChild,
                registerChild,
                isPanel: true,
                isOpen,
                onClick() {
                  setIsOpen(isOpen => !isOpen)
                },
              }}
            >
              {child}
            </NavigationContextProvider>
          )
        }
        if (child.type === NavigationPanel) {
          return (
            <NavigationContextProvider
              value={{
                activeChild,
                registerChild,
                isOpen,
                onClose: handleClose,
              }}
            >
              {child}
            </NavigationContextProvider>
          )
        }

        return child
      }),
    [activeChild, children, handleClose, isOpen, registerChild]
  )
  const MaybeAccordionItem =
    panelLayout === 'accordion' ? AccordionItem : Fragment

  return <MaybeAccordionItem>{items}</MaybeAccordionItem>
}
