import React from 'react'
import { OrderedList as ChakraOrderedList } from '@chakra-ui/react'

export const OrderedList: React.FC<
  React.PropsWithChildren<
    Pick<
      React.ComponentProps<typeof ChakraOrderedList>,
      'as' | 'sx' | 'children' | 'spacing'
    >
  >
> = props => <ChakraOrderedList {...props} />
