import React, { ReactElement } from 'react'
import { LinkContext } from '../../context'
import * as paths from './paths'
import { ILink, SiteFooter } from './site-footer'
import { WestfieldMembershipContainer } from './westfield-membership-container'
import { LeftColumnContainer } from './left-column-container'
import { Link as LinkType } from '@scentregroup/shared/types/link'

export interface UniversalFooterProps {
  taskMode?: boolean
  Link: LinkType
  centre?: React.ComponentProps<typeof LeftColumnContainer>['centre']
  country: string
  onFindACentre?: () => void
}

export const UniversalFooter = (props: UniversalFooterProps): ReactElement => {
  const { country, centre, Link, onFindACentre, taskMode } = props

  const links: ILink[] = [
    { label: 'Careers', href: paths.CAREERS },
    { label: 'Business Solutions', href: paths.BUSINESS_SOLUTIONS },
    { label: 'Responsible Business', href: paths.RESPONSIBLE_BUSINESS },
    { label: 'Privacy Policy', href: paths.PRIVACY_POLICY },
    { label: 'Terms and Conditions', href: paths.TERMS_CONDITIONS },
  ]

  return (
    <SiteFooter links={links} country={country} centreSlug={centre?.slug}>
      {taskMode ? null : (
        <LinkContext.Provider value={({ ...props }) => <Link {...props} />}>
          <LeftColumnContainer
            centre={centre}
            country={country}
            onFindACentre={onFindACentre}
          />
          <WestfieldMembershipContainer country={country} />
        </LinkContext.Provider>
      )}
    </SiteFooter>
  )
}

UniversalFooter.displayName = 'UniversalFooter'
