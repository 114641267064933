import React, { ReactElement, ReactNode } from 'react'
import { Logo } from '@hub/logos'
import { Box } from '@hub/box'
import { Text } from '@hub/text'
import { Link } from '@hub/link'
import { ListItem, UnorderedList } from '@hub/list'
import { MenuLink } from '@hub/navigation'
import {
  RESPONSIBLE_BUSINESS,
  RESPONSIBLE_BUSINESS_REPORT,
  SCENTRE_GROUP_HOME,
} from '../paths'
import { AcknowledgementSection } from './acknowledgement-section'

export interface ILink {
  label: string
  href: string
}

interface ISiteFooterProps {
  children: ReactNode
  links: ILink[]
  country: string
  centreSlug?: string | undefined
}

export const SiteFooter = ({
  children,
  links = [],
  country,
  centreSlug = undefined,
}: ISiteFooterProps): ReactElement => {
  return (
    <Box
      as={'footer'}
      sx={{
        flexShrink: 0,
        backgroundColor: 'surfaceBrandPrimary',
        color: 'textPrimary',
        fontSize: ['font-body'],
      }}
      role="contentinfo"
    >
      <Box
        sx={{
          marginX: 'auto',
          maxWidth: 'size-max-fixed',
          paddingX: ['spacing-mobile-margin', null, null, 'spacing-3xl'],
          paddingTop: ['spacing-xl'],
          paddingBottom: ['spacing-lg', null, null, 'spacing-xl'],
        }}
      >
        <Logo
          variant="westfield-red"
          sx={{ maxHeight: 'size-6', verticalAlign: 'top' }}
        />

        {children && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', null, 'row'],
              justifyContent: 'space-between',
              paddingY: ['spacing-lg', null, 'spacing-xl', 'spacing-xl'],
            }}
          >
            {children}
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderTop: children ? '1px solid' : 'none',
            borderTopColor: 'quartz',
            paddingTop: ['spacing-lg', null, 'spacing-xl'],
          }}
        >
          {country && (
            <>
              <AcknowledgementSection
                country={country}
                centreSlug={centreSlug}
              />
            </>
          )}
          <Text
            sx={{
              marginTop: 'spacing-lg',
              '& a': { textDecoration: 'underline' },
            }}
          >
            Scentre Group operates its Westfield Living Centre portfolio with a{' '}
            <a href={RESPONSIBLE_BUSINESS}>responsible business</a> mindset
            across the four pillars of community, people, environment and
            economic performance. More information can be found in our{' '}
            <a href={RESPONSIBLE_BUSINESS_REPORT}>
              2023 Responsible Business Report
            </a>
            .
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              flexDirection: ['column', null, null, 'row'],
              marginTop: [null, null, null, 'spacing-lg'],
            }}
          >
            {links && (
              <UnorderedList
                sx={{
                  listStyle: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: ['column', null, null, 'row'],
                  marginLeft: 0,
                  marginTop: ['spacing-lg', null, null, 0],
                }}
              >
                {links.map(({ label, href }, index) => (
                  <ListItem
                    sx={{
                      marginRight: 'spacing-md',
                      ...(index > 0 && {
                        marginTop: ['spacing-md', null, 0],
                      }),
                    }}
                    key={href}
                  >
                    <MenuLink sx={{ fontSize: 'font-body' }} href={href}>
                      {label}
                    </MenuLink>
                  </ListItem>
                ))}
              </UnorderedList>
            )}
            <Text
              sx={{
                order: [1, null, null, 2],
                paddingY: 'spacing-md',
                marginTop: ['spacing-lg', null, null, 0],
              }}
              lineHeight="none"
            >
              © All rights reserved{' '}
              <Link
                isExternal={true}
                hasUnderline={true}
                href={SCENTRE_GROUP_HOME}
              >
                Scentre Group
              </Link>{' '}
              {new Date().getFullYear()}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'shapeBrandPrimary', height: 'size-xs' }}
      ></Box>
    </Box>
  )
}
