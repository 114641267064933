import React from 'react'
import { List, ListItem } from '@hub/list'
import { MenuLink } from '../../../../packages/hub/navigation/src/menu-link'
import { LinkItem, MegaMenuLinkItem } from './types'

export const MegaMenuItem: React.FC<{
  megaMenuItem: MegaMenuLinkItem
  onClick?: (
    event: React.MouseEvent,
    path: (MegaMenuLinkItem | LinkItem)[]
  ) => void
}> = ({ megaMenuItem, onClick }) => {
  return (
    <List>
      <ListItem>
        <MenuLink
          tone="strong"
          onClick={event => onClick?.(event, [megaMenuItem])}
          href={megaMenuItem.url}
        >
          {megaMenuItem.label}
        </MenuLink>
      </ListItem>
      {megaMenuItem.items &&
        megaMenuItem.items.map((subItem, i) => (
          <ListItem key={i}>
            <MenuLink
              href={subItem.url}
              onClick={event => onClick?.(event, [megaMenuItem, subItem])}
            >
              {subItem.label}
            </MenuLink>
          </ListItem>
        ))}
    </List>
  )
}
