import { Document } from '@contentful/rich-text-types'
import {
  AdConfigType,
  DisplayAnalyticsMetadata,
  HighlightAttributes,
} from '@scentregroup/shared/graphql/__generated__/graphql-codegen'

export interface SGPageTemplate {
  title: string
  metaTitle?: string
  shortDescription?: string | null
  modules: SGPageTemplateModule[]
  analyticsMetadata?: DisplayAnalyticsMetadata
}

export type SGPageTemplateModule =
  | SGPageTemplateBasicContent
  | SGPageTemplateContentCollection
  | SGPageTemplateDynamicContent
  | SGPageTemplateRichTextContent
  | SGPageTemplateFlexibleGrid
  | SGPageTemplateDownloadWestfieldApp

export type SGPageTemplateDownloadWestfieldApp = {
  __typename: 'SGPageTemplateDownloadWestfieldApp'
  bodyCopy: Document | null
  image?: SGPageTemplateImage | null
}

export type SGPageTemplateFlexibleGrid = {
  __typename: 'SGPageTemplateFlexibleGrid'
  items: SGPageTemplateFlexibleGridItem[]
  maxColumns: number
}

export type SGPageTemplateFlexibleGridItem = {
  __typename?: 'SGPageTemplateFlexibleGridItem'
  bodyCopy?: Document | null
  image?: SGPageTemplateImage | null
  secondaryImage?: SGPageTemplateImage | null
}

export type SGPageTemplateBasicContent = {
  __typename: 'SGPageTemplateBasicContent'
  title?: string | null
  shortDescription?: string | null
  bodyCopy?: string | null
  callsToAction: SGPageTemplateCallToAction[]
} & {
  /**
   * indicates the Basic Content title should be rendered as an H2 instead of an H1
   *
   * note: not part of Contentful schema
   */
  titleAs?: 'h2'
}

export type SGPageTemplateRichTextContent = {
  __typename: 'SGPageTemplateRichTextContent'
  bodyCopy?: Document | null
}

export type SGPageTemplateContentCollection = {
  __typename: 'SGPageTemplateContentCollection'
  kind: SGPageTemplateContentCollectionKind
  title?: string | null
  shortDescription?: string | null
  items: SGPageTemplateContentEntry[]
  callToAction?: SGPageTemplateCallToAction | null
  analyticsMetadata?: SGPageTemplateDisplayAnalyticsMetadata
}

export type SGPageTemplateDisplayAnalyticsMetadata = {
  correlationIds: string[]
  displayId: string
  position: string | null
}

export type SGPageTemplateContentCollectionKind =
  | 'BANNER'
  | 'CATEGORY_GRID'
  | 'COLOUR_CARDS'
  | 'FEATURE'
  | 'INLINE_DECK'
  | 'STORE_GRID'
  | 'UTILITY_PANEL'
  | 'SPOTLIGHT'
  | 'WHATS_HAPPENING_DECK'

export type SGPageTemplateContentAnalyticsMetadata = {
  correlationId: string
  promotion: {
    creative: string | null
  }
  title: string
}

export type ContentOrchestrationAnalyticsMetadataStandard = {
  __typename: 'ContentOrchestrationAnalyticsMetadataStandard'
  displayId: string
  moduleSlug: string | null
}

export type ContentOrchestrationAnalyticsMetadataPersonalised = {
  __typename: 'ContentOrchestrationAnalyticsMetadataPersonalised'
  displayId: string
  moduleSlug: string | null
  primaryRank: number
  secondaryRank: number
  methodUsed: string
  knownScgId: boolean
}

export type ContentOrchestrationAnalyticsMetadata =
  | ContentOrchestrationAnalyticsMetadataStandard
  | ContentOrchestrationAnalyticsMetadataPersonalised

export type SGPageTemplateContentEntry = {
  __typename: 'SGPageTemplateContentEntry'
  adConfig?: AdConfigType | null
  kind: string
  title: string
  image: SGPageTemplateImage
  secondaryImage?: SGPageTemplateImage | null
  shortDescription?: string | null
  callToAction: SGPageTemplateCallToAction
  textColour?: string | null
  analyticsMetadata?: SGPageTemplateContentAnalyticsMetadata
  orchestrationMetadata?: ContentOrchestrationAnalyticsMetadata
  supplementaryText?: string | null
  memberExclusive?: boolean
  highlightAttribute?: HighlightAttributes | null
}

export type SGPageTemplateCallToAction = {
  label: string
  url: string
}

export type SGPageTemplateImage = {
  type: SGPageTemplateImageType
  url: string
  alt?: string | null
  supportingColour?: string | null
  cinemagraphUrl?: string | null
}

export const sgPageTemplateImageTypeValues = ['SQUARE', 'WIDE'] as const
export type SGPageTemplateImageType =
  (typeof sgPageTemplateImageTypeValues)[number]

export type SGPageTemplateDynamicContent = {
  __typename: 'SGPageTemplateDynamicContent'
  kind: string
}
