import React from 'react'
import {
  FormControl as ChakraFormControl,
  FormControlProps,
  FormLabel as ChakraFormLabel,
  FormLabelProps,
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps,
  FormHelperText as ChakraFormHelperText,
  TextProps,
} from '@chakra-ui/react'
import { injectTheme } from '@hub/design-system-base'
import { formTheme } from './theme'

injectTheme('Form', formTheme)

export const FormControl: React.FC<
  React.PropsWithChildren<
    Pick<
      FormControlProps,
      | 'as'
      | 'sx'
      | 'children'
      | 'isDisabled'
      | 'isReadOnly'
      | 'isRequired'
      | 'isInvalid'
      | 'label'
      | 'id'
    >
  >
> = props => <ChakraFormControl {...props} />

export const FormLabel: React.FC<
  React.PropsWithChildren<
    Pick<
      FormLabelProps,
      'as' | 'sx' | 'children' | 'htmlFor' | 'requiredIndicator'
    >
  >
> = props => <ChakraFormLabel {...props} />

export const FormErrorMessage: React.FC<
  React.PropsWithChildren<Pick<FormErrorMessageProps, 'as' | 'sx' | 'children'>>
> = props => <ChakraFormErrorMessage {...props} />

export const FormHelperText: React.FC<
  React.PropsWithChildren<Pick<TextProps, 'as' | 'sx' | 'children'>>
> = props => <ChakraFormHelperText {...props} />
