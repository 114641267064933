import { useMemo } from 'react'
import {
  AutocompleteSource,
  GetSourcesParams,
} from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import { SearchClient } from 'algoliasearch'

import {
  Clients,
  segmentIndexes,
  segmentParams,
  whichClient,
} from '../query-segments'
import {
  BaseItem,
  QueryContext,
  SearchContext,
  SearchSuggestionsBaseHit,
  Segment,
  SegmentedHit,
} from '../types'
import getUrlForHit from '../get-url-for-hit'
import getDisplayNameForHit from '../get-display-name-for-hit'
import {
  AlgoliaAnalyticsTagsRepositories,
  AlgoliaAnalyticsTagsUses,
} from '../algolia-analytics-tags'
import {
  getSegmentsToFetch,
  searchSuggestionsContextFromQueryContext,
} from '../algolia-helpers'
import useFeatureFlag from '../../../hooks/use-feature-flag'
import { FeatureFlagName } from '../../../feature-flags'
import useAnalyticsUserId from '@scentregroup/shared/hooks/use-analytics-user-id'
import { MIN_LIVE_SEARCH_QUERY_LENGTH } from '@scentregroup/shared/constants'

interface SourceParams {
  query: string
  clients: Clients
  context: SearchContext
  segment: Segment
  userToken: string
}

type BaseParams = Parameters<typeof getAlgoliaResults<BaseItem>>[0]
type RawTransformResponse = BaseParams['transformResponse']
type RawQueries = BaseParams['queries']
type RawSearchClient = BaseParams['searchClient']

interface GetAlgoliaResultsMyTypeArg {
  searchClient: SearchClient
  transformResponse: RawTransformResponse
  queries: RawQueries
}

const getAlgoliaResultsMyType = ({
  transformResponse,
  queries,
  searchClient,
}: GetAlgoliaResultsMyTypeArg): ReturnType<
  typeof getAlgoliaResults<BaseItem>
> => {
  // TODO this cast wasn't necessary under yarn
  // which is very confusing, but the runtime code
  // has been working so for now after getting
  // nowhere investigating it I'm just leaving it
  return getAlgoliaResults<BaseItem>({
    transformResponse,
    queries,
    searchClient: searchClient as unknown as RawSearchClient,
  })
}

const RESULTS_PER_INDEX = 15

type TransformResponse = Exclude<RawTransformResponse, undefined>

type AutocompleteHit = Parameters<TransformResponse>[0]['hits'][0][0]
type Segmenter = (h: AutocompleteHit) => SegmentedHit

function makeSegmenter(segment: Segment): Segmenter {
  return autocompleteHit =>
    ({
      ...autocompleteHit,
      segment,
    }) as SegmentedHit
  // this cast is required to convinc TS we've attached the
  // right segment to the right hit, I believe
}

function makeTransformResponse(segment: Segment): TransformResponse {
  const segmenter = makeSegmenter(segment)
  const transformResponse: TransformResponse = ({ hits }) => {
    const segmented = hits.map(queryResults => queryResults.map(segmenter))
    //  Not sure why the following cast seems to be needed :-/
    return segmented as ReturnType<TransformResponse>
  }
  return transformResponse
}

const makeTransformSearchSuggestionsResponse = (
  context: QueryContext
): TransformResponse => {
  const transform: TransformResponse = ({ hits }) => {
    const segmenter = makeSegmenter(Segment.searchSuggestions)
    // Again unsure why all the following casts are needed?
    const hitsForFirstQuery = hits[0] as unknown as SearchSuggestionsBaseHit[]
    const baseHit =
      hitsForFirstQuery.find(hit => hit.centreSlug === context.centre?.slug) ??
      hitsForFirstQuery.find(
        hit => hit.context === searchSuggestionsContextFromQueryContext(context)
      )
    const links = (baseHit?.links ?? []) as unknown as AutocompleteHit[]
    const segmented = links.map(segmenter)
    return segmented as ReturnType<TransformResponse>
  }
  return transform
}

type GetItems = AutocompleteSource<BaseItem>['getItems']

const analyticsTags = [
  AlgoliaAnalyticsTagsRepositories.WebsiteClient,
  AlgoliaAnalyticsTagsUses.LiveSearch,
]

function getItemsForSegment(
  searchClient: SearchClient,
  query: string,
  context: SearchContext,
  segment: Segment,
  userToken: string
): ReturnType<GetItems> {
  if (query.length < MIN_LIVE_SEARCH_QUERY_LENGTH) {
    return []
  }
  const searchParams = segmentParams[segment](context)
  // N.B. null indicates this segment should not be searched in this context
  // a lack of parameters is indicated by {}
  if (searchParams === null) {
    return []
  }
  const indexName = segmentIndexes[segment]
  const queryObject = {
    indexName,
    query,
    params: {
      ...searchParams,
      hitsPerPage: RESULTS_PER_INDEX,
      analyticsTags,
      userToken,
    },
  }
  return getAlgoliaResultsMyType({
    searchClient,
    transformResponse: makeTransformResponse(segment),
    queries: [queryObject],
  })
}

const makeSource = ({
  clients,
  context,
  segment,
  userToken,
}: SourceParams): AutocompleteSource<BaseItem> => ({
  sourceId: segment,
  getItems({ query }) {
    const client = clients[whichClient(segment)]
    return getItemsForSegment(client, query, context, segment, userToken)
  },
  getItemInputValue({ item }) {
    return getDisplayNameForHit(item)
  },
  getItemUrl({ item }) {
    return getUrlForHit(item, context)
  },
  onSelect({ itemUrl }) {
    if (itemUrl) {
      window.location.assign(itemUrl)
    }
  },
})

type JustQuery<T extends Record<string, unknown>> = Pick<
  GetSourcesParams<T>,
  'query'
>

export type GetSources<T extends Record<string, unknown>> = (
  params: JustQuery<T>
) => AutocompleteSource<T>[]

const getSearchSuggestionSources = (
  clients: Clients,
  context: SearchContext,
  userToken: string
): AutocompleteSource<BaseItem>[] => [
  {
    sourceId: Segment.searchSuggestions,
    async getItems() {
      const transformResponse = makeTransformSearchSuggestionsResponse(context)
      const searchParams = segmentParams[Segment.searchSuggestions](context)
      const queryObject = {
        indexName: segmentIndexes[Segment.searchSuggestions],
        query: '*',
        userToken,
        params: {
          ...searchParams,
          hitsPerPage: RESULTS_PER_INDEX,
        },
      }
      return getAlgoliaResultsMyType({
        searchClient: clients.content,
        transformResponse,
        queries: [queryObject],
      })
    },
    getItemInputValue({ item }) {
      return item.title as string
    },
    getItemUrl({ item }) {
      return getUrlForHit(item, context)
    },
    onSelect({ itemUrl }) {
      if (itemUrl) {
        window.location.assign(itemUrl)
      }
    },
  },
]

export function makeGetSources(
  clients: Clients,
  context: SearchContext,
  segmentsToFetch: Segment[],
  userToken: string
): GetSources<BaseItem> {
  return ({ query }) => {
    if (
      query.length === 0 &&
      (context.centre || context.specialSearchKind === 'stores')
    ) {
      return getSearchSuggestionSources(clients, context, userToken)
    }

    return segmentsToFetch.map(segment =>
      makeSource({
        clients,
        query,
        context,
        segment,
        userToken,
      })
    )
  }
}

function useNormalGetSources(
  clients: Clients | undefined,
  context: SearchContext
): GetSources<BaseItem> {
  const userToken = useAnalyticsUserId()

  const getSources = useMemo(() => {
    if (!clients) {
      return () => []
    }
    return (params: JustQuery<BaseItem>) => {
      const segmentsToFetch = getSegmentsToFetch(context)
      return makeGetSources(
        clients,
        context,
        segmentsToFetch,
        userToken
      )(params)
    }
  }, [clients, context, userToken])
  return getSources
}

export default function useGetSources(
  clients: Clients | undefined,
  context: SearchContext
): GetSources<BaseItem> {
  const isCuratedTopStores =
    context.query.length === 0 && context.specialSearchKind === 'stores'
  const disableLiveSearch = useFeatureFlag(
    FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH
  )
  const normalGetSources = useNormalGetSources(clients, context)
  const emptyGetSources: GetSources<BaseItem> = () => []
  return isCuratedTopStores || !disableLiveSearch
    ? normalGetSources
    : emptyGetSources
}
