import moment from 'moment'
import { ContentAnalyticsMetaData } from './contentful'
import { HighlightAttributes } from '@scentregroup/shared/graphql/__generated__/graphql-codegen'

export declare type MDate = moment.Moment
export interface WhatsOnImage {
  sourceDesktop: string
  sourceMobile: string
  description: string
}

export interface WhatsOnEvent {
  name: string
  time: string
  price: string
  location: string
  bookingUrl?: string
  image: WhatsOnImage
  slug: string
  topics: string[]
  experiences: string[]
  contentTypes: ['Events']
  analyticsMetadata: ContentAnalyticsMetaData
  memberExclusive: boolean
  highlightAttribute: HighlightAttributes | null
}

export interface WhatsOnOffer {
  name: string
  dates: string
  location: string
  image: WhatsOnImage
  slug: string
  timezonelessShowAtTimestamp: number
  topics: string[]
  experiences: string[]
  contentTypes: ['Offers']
  analyticsMetadata: ContentAnalyticsMetaData
  memberExclusive: boolean
  highlightAttribute: HighlightAttributes | null
}

export interface WhatsOnOccurrence<TItem> {
  item: TItem
  startAt: MDate
  endAt: MDate
}

export type WhatsOnItem = WhatsOnEvent | WhatsOnOffer

export type WhatsOnEventOccurrence = WhatsOnOccurrence<WhatsOnEvent>

export type WhatsOnOfferOccurrence = WhatsOnOccurrence<WhatsOnOffer>

export interface WhatsOnRange {
  name: string
  slug: string
  items: WhatsOnItem[]
}

export enum TimeRangeNames {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  ThisWeek = 'This Week',
  ThisWeekend = 'This Weekend',
  NextWeek = 'Next Week',
  Upcoming = 'Upcoming',
  Never = 'Never',
}

export interface TimeRange {
  isValid(): boolean
  slug(): string
  isInRange(date: MDate): boolean
  startsAt: MDate
  endsAt: MDate
  name: TimeRangeNames
}

export interface WhatsOnSelectedFilters {
  dateRange: string
  topics: string[]
  experiences: string[]
  contentTypes: string[]
}
