import {
  Navigation,
  NavigationLink,
  NavigationPanel,
  NavigationPanelBody,
  NavigationPanelCloseButton,
  NavigationPanelHeader,
} from '@hub/navigation'
import React from 'react'
import { Text } from '@hub/text'
import { H3 } from '@hub/heading'
import useIsMounted from '../../../hooks/use-is-mounted'
import { useIsRouteChanging, useRouter } from '../../../next/router'
import { LOGOUT } from '../../../paths'
import { getAbsolutePathFromRouter } from '../../../helpers/url'
import { INavigationMenuItem } from '../types'

type HeaderSecondaryMenuProps = {
  isOpen?: boolean
  containerRef?: React.RefObject<HTMLElement | null>
  name?: string
  fadeDuration?: number
  onClose?: () => void
  onClickLink?: (event: React.MouseEvent, path: INavigationMenuItem[]) => void
}

export const HeaderSecondaryMenu: React.FC<HeaderSecondaryMenuProps> = ({
  containerRef,
  isOpen,
  name,
  fadeDuration,
  onClose,
  onClickLink,
}) => {
  const router = useRouter()
  const isMounted = useIsMounted()
  const isRouteChanging = useIsRouteChanging()
  if (!isMounted) {
    return null
  }
  const currentPath = getAbsolutePathFromRouter(router)
  const logoutUrl = getAbsolutePathFromRouter({
    route: LOGOUT,
    basePath: '/account',
    query: {
      path:
        currentPath === '' || currentPath.startsWith('/account')
          ? '/'
          : currentPath,
    },
  })

  const accountMenuItem = {
    label: 'Account',
    url: '/account',
  }
  const logoutMenuItem = {
    label: 'Sign out',
    url: logoutUrl,
  }
  const secondaryMenu = {
    title: 'Menu',
    items: [accountMenuItem, logoutMenuItem],
  }

  return (
    <Navigation
      allowOpen={!isRouteChanging}
      containerRef={containerRef}
      placement="right"
      onClose={onClose}
      trigger="click"
      fadeDuration={fadeDuration}
      backgroundColor="surfaceBrandPrimary"
    >
      <NavigationPanel isOpen={isOpen}>
        <NavigationPanelHeader>
          <NavigationPanelCloseButton />
        </NavigationPanelHeader>
        <NavigationPanelBody>
          {name && <H3 as={Text}>Hi, {name}!</H3>}
          <NavigationLink
            href={accountMenuItem.url}
            onClick={event =>
              onClickLink?.(event, [secondaryMenu, accountMenuItem])
            }
          >
            {accountMenuItem.label}
          </NavigationLink>
          <NavigationLink
            href={logoutMenuItem.url}
            onClick={event =>
              onClickLink?.(event, [secondaryMenu, logoutMenuItem])
            }
          >
            {logoutMenuItem.label}
          </NavigationLink>
        </NavigationPanelBody>
      </NavigationPanel>
    </Navigation>
  )
}
