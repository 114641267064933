import React from 'react'
import { HubStyleObject } from '@hub/design-system-base'
import { Box } from '@hub/box'
import Image from '../image'
import TextWithHighlights from './text-with-highlights'
import SearchRow from './search-row'
import { HitType, isPageHit, WestfieldProductHit } from './types'
import { FieldOptions } from './display-utils'

interface SearchResultProps {
  href: string
  imageUrl?: string
  highlightField: FieldOptions
  secondaryHighlightedContent?: string
  description?: string
  hit: Exclude<HitType, WestfieldProductHit>
  index?: number
  hitKind: string
  noImage?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  isTextBold?: boolean
  imageBgSize?: 'cover' | 'contain'
  isStoreRow?: boolean
}

type ResultTextProps = Pick<
  SearchResultProps,
  | 'hit'
  | 'highlightField'
  | 'isTextBold'
  | 'secondaryHighlightedContent'
  | 'description'
>

const textSx: HubStyleObject = {
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  'text-overflow': 'ellipsis',
  'white-space': 'normal',
} as const

const ResultText: React.FC<ResultTextProps> = ({
  hit,
  highlightField,
  secondaryHighlightedContent,
  description,
  isTextBold = true,
}) => (
  <>
    <TextWithHighlights
      hit={hit}
      field={highlightField}
      isTextBold={isTextBold}
      sx={textSx}
    />
    {secondaryHighlightedContent && (
      <Box sx={textSx}>{secondaryHighlightedContent}</Box>
    )}
    {description && (
      <Box
        sx={{
          ...textSx,
          color: 'textSecondary',
          fontWeight: 'lighter',
          fontSize: 'font-body',
        }}
      >
        {description}
      </Box>
    )}
  </>
)

const SearchResult: React.FC<React.PropsWithChildren<SearchResultProps>> = ({
  href,
  hit,
  hitKind,
  imageUrl,
  noImage = false,
  onClick,
  imageBgSize = 'cover',
  isStoreRow,
  highlightField,
  secondaryHighlightedContent,
  description,
  isTextBold,
}) => {
  return (
    <SearchRow
      href={href}
      hitKind={hitKind}
      key={hit.objectID}
      target={isPageHit(hit) && hit.isNewTab ? '_blank' : '_self'}
      onClick={onClick}
    >
      {!noImage && (
        <Image
          imageUrl={imageUrl}
          ratio="square"
          bgSize={imageBgSize}
          sx={{
            float: 'left',
            width: 'size-md',
            height: 'size-md',
            marginRight: { base: 'spacing-md', md: 'spacing-lg' },
            ...(isStoreRow
              ? { bgColor: 'surfaceTertiary', top: ['size-1', null, 'size-3'] }
              : {}),
          }}
        />
      )}
      <ResultText
        highlightField={highlightField}
        secondaryHighlightedContent={secondaryHighlightedContent}
        description={description}
        hit={hit}
        isTextBold={isTextBold}
      />
    </SearchRow>
  )
}

export default SearchResult
