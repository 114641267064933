import { H4 } from '@hub/heading'
import {
  Navigation,
  NavigationButton,
  NavigationLink,
  NavigationPanel,
  NavigationPanelBackButton,
  NavigationPanelBody,
  NavigationPanelCloseButton,
  NavigationPanelHeader,
  NavigationPanelMenu,
} from '@hub/navigation'
import React, { useCallback, useMemo, useState } from 'react'
import { Text } from '@hub/text'
import { Stack } from '@hub/stack'
import { Button } from '@hub/button'
import { INavigationMenu, INavigationMenuItem } from '../types'
import { getAbsolutePathFromRouter } from '../../../helpers/url'
import { useIsRouteChanging } from '../../../next/router'
import { useCentres } from '../../../centres'
import { FindCentreMenu } from './find-centre-menu'
import { AccordionMenu } from './accordion-menu'

type HeaderPrimaryMenuProps = {
  centre?: {
    slug: string
    title: string
    navigationMenu: INavigationMenu
  }
  country: 'Australia' | 'New Zealand'
  giftCards?: INavigationMenu
  isOpen?: boolean
  fadeDuration?: number
  onClose?: () => void
  onClickLink?: (event: React.MouseEvent, path: INavigationMenuItem[]) => void
  onClickButton?: (
    event: React.MouseEvent,
    action: 'open' | 'close',
    path: INavigationMenuItem[]
  ) => void
}

type PanelName = 'visit-centre' | 'find-a-centre'
const useControlledPanel = (
  onClose?: () => void
): [
  openedPanel: PanelName | null,
  toggleOpenedPanel: (name?: PanelName) => React.MouseEventHandler,
  onClose: () => void,
] => {
  const [openedPanel, setOpenedPanel] = useState<PanelName | null>(null)
  const handleClose = useCallback(() => {
    setOpenedPanel(null)
    onClose?.()
  }, [onClose])

  const toggleOpenedPanel = useCallback(
    (name?: PanelName): React.MouseEventHandler =>
      () => {
        setOpenedPanel(state => (name ? (state === name ? null : name) : null))
      },
    []
  )

  return [openedPanel, toggleOpenedPanel, handleClose]
}

export const HeaderPrimaryMenu: React.FC<HeaderPrimaryMenuProps> = ({
  centre,
  country,
  giftCards,
  isOpen,
  fadeDuration,
  onClose,
  onClickLink,
  onClickButton,
}) => {
  const [openedPanel, toggleOpenedPanel, handleClose] =
    useControlledPanel(onClose)

  const isRouteChanging = useIsRouteChanging()
  const centres = useCentres()

  const centresMenuItems = useMemo(
    () =>
      centres?.map(centre => ({
        centre,
        label: centre.title,
        url: getAbsolutePathFromRouter({
          route: '[centre]',
          basePath: '/',
          query: { centre: centre.slug },
        }),
      })),
    [centres]
  )

  const homeLink: INavigationMenuItem = {
    label: 'Explore Westfield',
    url: '/',
  }
  const theEditLink: INavigationMenuItem = {
    label: 'The Edit',
    url: '/the-edit',
  }
  const membershipLink: INavigationMenuItem = {
    label: 'Membership',
    url: '/membership',
  }
  const customerSupportLink: INavigationMenuItem = {
    label: 'Customer Support',
    url: (
      {
        ['Australia']: 'https://support.westfield.com.au',
        ['New Zealand']: 'https://support.westfield.co.nz',
      } as const
    )[country],
  }
  const findCentreMenu =
    (centresMenuItems && {
      title: 'Find a centre',
      items: centresMenuItems,
    }) ??
    undefined

  const findAStoreLink: INavigationMenuItem = {
    label: 'Find a store',
    url: '/stores',
  }

  const visitCentreMenu =
    (centre?.navigationMenu && {
      title: `Visit ${centre.title}`,
      items: (
        [
          {
            label: 'Home',
            url: getAbsolutePathFromRouter({
              route: '[centre]',
              basePath: '/',
              query: { centre: centre.slug },
            }),
          },
        ] as INavigationMenu
      ).concat(centre.navigationMenu),
    }) ??
    undefined

  const giftCardsMenu =
    (giftCards && {
      title: 'Gift cards',
      items: giftCards,
    }) ??
    undefined

  const primaryMenu: INavigationMenuItem = {
    title: 'Menu',
    items: [
      homeLink,
      ...(!centre && findCentreMenu ? [findCentreMenu] : []),
      ...(centre && visitCentreMenu ? [visitCentreMenu] : []),
      ...(!centre ? [findAStoreLink] : []),
      ...(giftCardsMenu ? [giftCardsMenu] : []),
      ...(!centre ? [theEditLink] : []),
      ...(centres.length && findCentreMenu ? [findCentreMenu] : []),
      membershipLink,
      customerSupportLink,
    ],
  }

  const handleClickButton =
    (item: INavigationMenuItem) =>
    (event: React.MouseEvent, context: { isOpen: boolean | undefined }) => {
      onClickButton?.(event, context.isOpen ? 'close' : 'open', [
        primaryMenu,
        item,
      ])
    }

  return (
    <Navigation
      placement="left"
      trigger="click"
      backgroundColor="surfaceBrandPrimary"
      fadeDuration={fadeDuration}
      allowOpen={!isRouteChanging}
      onClose={handleClose}
    >
      <NavigationPanel isOpen={isOpen ?? false}>
        <NavigationPanelHeader>
          <NavigationPanelCloseButton />
        </NavigationPanelHeader>
        <NavigationPanelBody>
          <NavigationLink
            size="2xl"
            href={homeLink.url}
            onClick={event => onClickLink?.(event, [primaryMenu, homeLink])}
          >
            {homeLink.label}
          </NavigationLink>
          {!centre && findCentreMenu && (
            <NavigationPanelMenu>
              <NavigationButton
                size="2xl"
                onClick={handleClickButton(findCentreMenu)}
              >
                {findCentreMenu.title}
              </NavigationButton>
              <NavigationPanel>
                <NavigationPanelHeader>
                  <NavigationPanelCloseButton
                    isMobileOnly
                    onClick={handleClickButton(findCentreMenu)}
                  />
                  <NavigationPanelBackButton
                    isMobileOnly
                    onClick={handleClickButton(findCentreMenu)}
                  />
                </NavigationPanelHeader>
                <NavigationPanelBody>
                  <FindCentreMenu
                    country={country}
                    items={findCentreMenu.items}
                    onClickLink={(event, path) =>
                      onClickLink?.(event, [
                        primaryMenu,
                        findCentreMenu,
                        ...path,
                      ])
                    }
                  />
                </NavigationPanelBody>
              </NavigationPanel>
            </NavigationPanelMenu>
          )}
          {centre && visitCentreMenu && (
            <NavigationPanelMenu>
              <NavigationButton
                size="2xl"
                onClick={(event, context) => {
                  handleClickButton(visitCentreMenu)(event, context)
                  toggleOpenedPanel('visit-centre')(event)
                }}
                active={openedPanel === 'visit-centre'}
              >
                {visitCentreMenu.title}
              </NavigationButton>
              <NavigationPanel isOpen={openedPanel === 'visit-centre'}>
                <NavigationPanelHeader>
                  <NavigationPanelCloseButton
                    isMobileOnly
                    onClick={handleClickButton(visitCentreMenu)}
                  />
                  <NavigationPanelBackButton
                    isMobileOnly
                    onClick={(event, context) => {
                      toggleOpenedPanel('visit-centre')(event)
                      handleClickButton(visitCentreMenu)(event, context)
                    }}
                  />
                </NavigationPanelHeader>
                <NavigationPanelBody layout="accordion">
                  <Stack direction="row" align="center" gap="spacing-md">
                    <H4 as={Text}>{centre.title}</H4>
                    <Button
                      colorScheme="licorice"
                      variant="plainText"
                      onClick={toggleOpenedPanel('find-a-centre')}
                    >
                      Change
                    </Button>
                  </Stack>
                  <AccordionMenu
                    items={visitCentreMenu.items}
                    onClickLink={(event, path) =>
                      onClickLink?.(event, [
                        primaryMenu,
                        visitCentreMenu,
                        ...path,
                      ])
                    }
                  />
                </NavigationPanelBody>
              </NavigationPanel>
            </NavigationPanelMenu>
          )}
          {!centre && (
            <NavigationLink
              size="2xl"
              href={findAStoreLink.url}
              onClick={event =>
                onClickLink?.(event, [primaryMenu, findAStoreLink])
              }
            >
              {findAStoreLink.label}
            </NavigationLink>
          )}
          {giftCardsMenu && (
            <NavigationPanelMenu>
              <NavigationButton
                size="2xl"
                onClick={(event, context) => {
                  toggleOpenedPanel()(event)
                  handleClickButton(giftCardsMenu)(event, context)
                }}
              >
                {giftCardsMenu.title}
              </NavigationButton>
              <NavigationPanel>
                <NavigationPanelHeader>
                  <NavigationPanelCloseButton
                    isMobileOnly
                    onClick={(event, context) => {
                      toggleOpenedPanel()(event)
                      handleClickButton(giftCardsMenu)(event, context)
                    }}
                  />
                  <NavigationPanelBackButton
                    isMobileOnly
                    onClick={(event, context) => {
                      toggleOpenedPanel()(event)
                      handleClickButton(giftCardsMenu)(event, context)
                    }}
                  />
                </NavigationPanelHeader>
                <NavigationPanelBody layout="accordion">
                  <H4 as={Text}>Gift cards</H4>
                  <AccordionMenu
                    items={giftCardsMenu.items}
                    onClickButton={(event, context, path) => {
                      onClickButton?.(
                        event,
                        context.isOpen ? 'close' : 'open',
                        [primaryMenu, giftCardsMenu, ...path]
                      )
                    }}
                    onClickLink={(event, path) =>
                      onClickLink?.(event, [
                        primaryMenu,
                        giftCardsMenu,
                        ...path,
                      ])
                    }
                  />
                </NavigationPanelBody>
              </NavigationPanel>
            </NavigationPanelMenu>
          )}
          {!centre && (
            <NavigationLink
              size="2xl"
              href={theEditLink.url}
              onClick={event =>
                onClickLink?.(event, [primaryMenu, theEditLink])
              }
            >
              {theEditLink.label}
            </NavigationLink>
          )}

          {centre && findCentreMenu && (
            <NavigationPanelMenu>
              <NavigationButton
                size="md"
                onClick={(event, context) => {
                  toggleOpenedPanel('find-a-centre')(event)
                  handleClickButton(findCentreMenu)(event, context)
                }}
                active={openedPanel === 'find-a-centre'}
              >
                {findCentreMenu.title}
              </NavigationButton>
              <NavigationPanel isOpen={openedPanel === 'find-a-centre'}>
                <NavigationPanelHeader>
                  <NavigationPanelCloseButton
                    isMobileOnly
                    onClick={handleClickButton(findCentreMenu)}
                  />
                  <NavigationPanelBackButton
                    isMobileOnly
                    onClick={(event, context) => {
                      toggleOpenedPanel('find-a-centre')(event)
                      handleClickButton(findCentreMenu)(event, context)
                    }}
                  />
                </NavigationPanelHeader>
                <NavigationPanelBody>
                  <FindCentreMenu
                    country={country}
                    items={findCentreMenu.items}
                    onClickLink={(event, path) =>
                      onClickLink?.(event, [
                        primaryMenu,
                        findCentreMenu,
                        ...path,
                      ])
                    }
                  />
                </NavigationPanelBody>
              </NavigationPanel>
            </NavigationPanelMenu>
          )}
          <NavigationLink
            size="md"
            href={membershipLink.url}
            openInNewWindow
            onClick={event =>
              onClickLink?.(event, [primaryMenu, membershipLink])
            }
          >
            {membershipLink.label}
          </NavigationLink>
          <NavigationLink
            size="md"
            href={customerSupportLink.url}
            onClick={event =>
              onClickLink?.(event, [primaryMenu, customerSupportLink])
            }
          >
            {customerSupportLink.label}
          </NavigationLink>
        </NavigationPanelBody>
      </NavigationPanel>
    </Navigation>
  )
}
