import { useEffect } from 'react'

export const useCloseOnEscape = (
  isOpen: boolean,
  onClose?: () => void
): void => {
  useEffect(() => {
    if (!isOpen) {
      return
    }
    const keyHandler = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onClose?.()
      }
    }
    window.addEventListener('keydown', keyHandler)
    return () => window.removeEventListener('keydown', keyHandler)
  }, [isOpen, onClose])
}
