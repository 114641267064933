import React, { createContext, useContext, useMemo } from 'react'
import type { FC, PropsWithChildren } from 'react'

export type NavigationContext = {
  activeChild?: symbol
  buttonRef?: React.RefObject<HTMLElement | null>
  height?: number | string
  isBar?: boolean
  isMenu?: boolean
  isOpen?: boolean
  isPanel?: boolean
  isSubPanel?: boolean
  navigationPlacement?: 'left' | 'right' | 'below'
  overlayContentRef?: React.RefObject<HTMLElement | null>
  panelPlacement?: 'left' | 'right'
  panelLayout?: 'panel' | 'accordion'
  registerChild?: (child: symbol, isOpen: boolean) => void
  trigger?: 'click' | 'hover'
  width?: number | string
  accordionExpandedIndex?: number | number[]
  onClick?: React.MouseEventHandler
  onClickButton?: (event: React.MouseEvent, data: unknown) => void
  onClickLink?: (event: React.MouseEvent, data: unknown) => void
  onClose?: (recursive?: boolean) => void
  onClosePanel?: () => void
  onMouseEnter?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
  onOpen?: (data?: unknown) => void
}

export const Context = createContext<NavigationContext>({})

export const useNavigationContext = (
  value?: NavigationContext
): NavigationContext => {
  const current = useContext(Context)
  return useMemo(() => {
    if (!value) {
      return current
    }
    const keys = Object.keys(value) as (keyof NavigationContext)[]
    const noChange = keys.every(key => value[key] === current[key])
    if (noChange) {
      return current
    }
    return { ...current, ...value }
  }, [current, value])
}

export const NavigationContextProvider: FC<
  PropsWithChildren<{
    value: NavigationContext
  }>
> = ({ value, children }) => {
  const next = useNavigationContext(value)
  return <Context.Provider value={next}>{children}</Context.Provider>
}
