import React from 'react'
import { AspectRatio as ChakraAspectRatio } from '@chakra-ui/react'
import {
  As,
  HubResponsiveValue,
  HubStyleObject,
  StandardSizes,
  forwardRef,
} from '@hub/design-system-base'

export const standardRatios: { [key: string]: number } = {
  '21/9': 21 / 9,
  '1.85/1': 1.85 / 1,
  '16/9': 16 / 9,
  '3/2': 3 / 2,
  '4/3': 4 / 3,
  '1': 1,
  '3/4': 3 / 4,
  '2/3': 2 / 3,
  '9/16': 9 / 16,
  '4.6/1': 4.6 / 1,
  '1.6/1': 1.6 / 1,
}

export type StandardRatios = keyof typeof standardRatios
interface FrameProps {
  ratio?: HubResponsiveValue<StandardRatios>
  minWidth?: HubResponsiveValue<StandardSizes>
  maxWidth?: HubResponsiveValue<StandardSizes>
  as?: As
  sx?: HubStyleObject
}

function getRatio(
  ratio: FrameProps['ratio']
): HubResponsiveValue<number> | undefined {
  if (typeof ratio === 'string') {
    return standardRatios[ratio] ?? undefined
  } else if (typeof ratio === 'number') {
    return ratio
  } else if (Array.isArray(ratio)) {
    return ratio.map(ratioOption =>
      ratioOption === null
        ? null
        : (getRatio(ratioOption) as number | undefined) ?? null
    )
  } else {
    return undefined
  }
}

export const Frame = forwardRef<FrameProps, typeof ChakraAspectRatio>(
  (
    { as, ratio = '16/9', minWidth, maxWidth = 'size-full', sx, children },
    ref
  ) => (
    <ChakraAspectRatio
      ref={ref}
      as={as}
      sx={sx}
      ratio={getRatio(ratio)}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      {children}
    </ChakraAspectRatio>
  )
)
