import React from 'react'
import { UnorderedList as ChakraUnorderedList } from '@chakra-ui/react'

export const UnorderedList: React.FC<
  React.PropsWithChildren<
    Pick<
      React.ComponentProps<typeof ChakraUnorderedList>,
      'as' | 'sx' | 'children' | 'spacing'
    >
  >
> = props => <ChakraUnorderedList {...props} />
