import { IAnalyticsContext } from '../../analytics/types'
import analyticsUtils from '../../helpers/analytics-utils'

type INavigationContext = {
  parentLabel: string
  link?: string
  subParentLabel?: string
  itemLabel?: string
}

const notUndefined = <T>(value: T | undefined): value is T =>
  value !== undefined

export function transformNavigationClickedItemsData(
  analytics: IAnalyticsContext,
  navigationContext: INavigationContext
): void {
  const { itemLabel, subParentLabel, parentLabel, link } = navigationContext
  const text = [itemLabel, subParentLabel, parentLabel].find(notUndefined)
  const hierarchy = [parentLabel, subParentLabel, itemLabel]
    .filter(notUndefined)
    .join(' > ')

  const url = link !== '#' ? link : undefined
  const eventLabel = [parentLabel, itemLabel].filter(notUndefined).join(' - ')

  analytics.trackEvent({
    object: 'Navigation',
    action: 'Clicked',
    properties: {
      label: 'navigation_clicked',
      category: 'Navigation',
      name: analyticsUtils.categories.UNIVERSAL_HEADER,
      text,
      hierarchy,
      url,
    },
    legacy: {
      event: 'user_interaction',
      eventCategory: analyticsUtils.categories.UNIVERSAL_HEADER,
      eventAction: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK,
      eventLabel,
    },
  })
}
