import React, { useCallback } from 'react'
import { useAnalyticsSimplified as useAnalytics } from '@scentregroup/analytics'
import { transformNavigationClickedItemsData } from '../../analytics-segment/events/navigation-item'
import analyticsUtils from '../../helpers/analytics-utils'
import trackClickEvent from '../../helpers/analytics-utils/track-event'
import {
  INavigationMenuItem,
  isNavigationLink,
  isNavigationMegaMenu,
  isNavigationSimpleMenu,
} from '../header/types'
import { Centre } from '../../centres'

type LayoutAnalyticsHandlers = {
  handleClickNavigationBarLink: (
    event: React.MouseEvent,
    path: INavigationMenuItem[]
  ) => void
  handleClickMenuLink: (
    event: React.MouseEvent,
    path: INavigationMenuItem[]
  ) => void
  handleClickMenuButton: (
    event: React.MouseEvent,
    action: 'open' | 'close',
    path: INavigationMenuItem[]
  ) => void
  handleClickLogo: (event: React.MouseEvent) => void
  handleClickSearchSubmit: (
    query: string,
    centre?: Pick<Centre, 'title'>
  ) => void
}

export function useLayoutAnalytics(): LayoutAnalyticsHandlers {
  const analytics = useAnalytics()
  const handleClickNavigationBarLink = useCallback<
    LayoutAnalyticsHandlers['handleClickNavigationBarLink']
  >(
    (_event, path) => {
      // console.log(
      //   path,
      //   path.map(item => [
      //     isNavigationLink(item),
      //     isNavigationSimpleMenu(item),
      //     isNavigationMegaMenu(item),
      //   ])
      // )
      // _event.preventDefault()

      if (path.length === 1 && isNavigationLink(path[0])) {
        const menuItem = path[0]
        transformNavigationClickedItemsData(analytics, {
          parentLabel: menuItem.label,
          link: menuItem.url,
        })
      }

      if (
        path.length === 2 &&
        isNavigationSimpleMenu(path[0]) &&
        isNavigationLink(path[1])
      ) {
        const itemLabel = path[0].title
        const menuItem = path[1]
        transformNavigationClickedItemsData(analytics, {
          parentLabel: itemLabel,
          itemLabel: menuItem.label,
          link: menuItem.url,
        })
      }
      if (
        path.length === 2 &&
        isNavigationMegaMenu(path[0]) &&
        isNavigationLink(path[1])
      ) {
        const itemLabel = path[0].title
        const menuItem = path[1]
        transformNavigationClickedItemsData(analytics, {
          parentLabel: itemLabel,
          itemLabel: menuItem.label,
          link: menuItem.url,
        })
      }
      if (
        path.length === 3 &&
        isNavigationMegaMenu(path[0]) &&
        isNavigationLink(path[2])
      ) {
        const itemLabel = path[0].title
        const menuItem = path[2]
        transformNavigationClickedItemsData(analytics, {
          parentLabel: itemLabel,
          itemLabel: menuItem.label,
          link: menuItem.url,
        })
      }
    },
    [analytics]
  )

  const handleClickMenuLink = useCallback<
    LayoutAnalyticsHandlers['handleClickMenuLink']
  >((_event, path) => {
    // console.log(
    //   path,
    //   path.map(item => [
    //     isNavigationLink(item),
    //     isNavigationSimpleMenu(item),
    //     isNavigationMegaMenu(item),
    //   ])
    // )
    // _event.preventDefault()
    if (
      path.length === 2 &&
      isNavigationSimpleMenu(path[0]) &&
      isNavigationLink(path[1])
    ) {
      const parentMenu = path[0]
      const menuItem = path[1]
      const i = parentMenu.items.findIndex(item => item === menuItem)
      trackClickEvent(
        analyticsUtils.categories.UNIVERSAL_HEADER,
        analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK,
        `L0-${i} ${menuItem.label}`
      )
    }
    if (
      path.length === 3 &&
      isNavigationSimpleMenu(path[1]) &&
      isNavigationLink(path[2])
    ) {
      const parentMenu = path[1]
      const menuItem = path[2]
      const i = parentMenu.items.findIndex(item => item === menuItem)
      trackClickEvent(
        analyticsUtils.categories.UNIVERSAL_HEADER,
        analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK,
        `L1-${i} ${menuItem.label}`
      )
    }
    if (
      path.length === 4 &&
      isNavigationSimpleMenu(path[1]) &&
      isNavigationSimpleMenu(path[2]) &&
      isNavigationLink(path[3])
    ) {
      const topMenu = path[1]
      const parentMenu = path[2]
      const menuItem = path[3]
      const j = topMenu.items.findIndex(item => item === parentMenu)
      const i = parentMenu.items.findIndex(item => item === menuItem)
      trackClickEvent(
        analyticsUtils.categories.UNIVERSAL_HEADER,
        analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK,
        `L1-${j}-${i} ${parentMenu.title} - ${menuItem.label}`
      )
    }
  }, [])

  const handleClickMenuButton = useCallback<
    LayoutAnalyticsHandlers['handleClickMenuButton']
  >((_event, action, path) => {
    // console.log(
    //   action,
    //   path,
    //   path.map(item => [
    //     isNavigationLink(item),
    //     isNavigationSimpleMenu(item),
    //     isNavigationMegaMenu(item),
    //   ])
    // )
    // _event.preventDefault()
    if (path.length === 2 && path.every(isNavigationSimpleMenu)) {
      const eventAction = {
        open: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_OPEN,
        close: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_CLOSE,
      }[action]
      const parentMenu = path[0]
      const menuItem = path[1]
      const i = parentMenu.items.findIndex(item => item === menuItem)
      trackClickEvent(
        analyticsUtils.categories.UNIVERSAL_HEADER,
        eventAction,
        `L0-${i} ${parentMenu.title} - ${menuItem.title}`
      )
    }
    if (
      action === 'open' && // to match legacy behaviour only track 'open' events in accordion menus
      path.length === 3 &&
      path.every(isNavigationSimpleMenu)
    ) {
      const eventAction = {
        open: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_OPEN,
        close: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_CLOSE,
      }[action]
      const parentMenu = path[1]
      const menuItem = path[2]
      const i = parentMenu.items.findIndex(item => item === menuItem)
      trackClickEvent(
        analyticsUtils.categories.UNIVERSAL_HEADER,
        eventAction,
        `L1-${i} ${menuItem.title}`
      )
    }
  }, [])

  const handleClickLogo = useCallback<
    LayoutAnalyticsHandlers['handleClickLogo']
  >(_event => {
    // _event.preventDefault()
    trackClickEvent(
      analyticsUtils.categories.UNIVERSAL_HEADER,
      analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK,
      'Westfield Logo'
    )
  }, [])

  const handleClickSearchSubmit = useCallback<
    LayoutAnalyticsHandlers['handleClickSearchSubmit']
  >(
    (query, centre) => {
      analytics.trackEvent({
        object: 'Global',
        action: 'Searched',
        properties: {
          category: 'Search',
          label: 'global_searched',
          search_type: centre ? 'Centre' : 'Westfield Direct',
          ...(centre && { centre_context: centre.title }),
          keyword: query,
        },
      })
    },
    [analytics]
  )

  return {
    handleClickNavigationBarLink,
    handleClickMenuLink,
    handleClickMenuButton,
    handleClickLogo,
    handleClickSearchSubmit,
  }
}
