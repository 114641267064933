import React from 'react'
import { Avatar as ChakraAvatar, AvatarProps } from '@chakra-ui/react'
import { injectTheme } from '@hub/design-system-base'
import { avatarTheme } from './theme'

injectTheme('Avatar', avatarTheme)

export const Avatar: React.FC<
  React.PropsWithChildren<
    Pick<AvatarProps, 'as' | 'sx' | 'children' | 'name' | 'src'>
  >
> = props => <ChakraAvatar {...props} />
