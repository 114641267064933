import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'
import { RawCookieName } from '../constants'
import { useGetHrefContext } from '../context'
import { MaybeURL } from '../helpers/url'
import useOnce from './use-once'

const { SCENTREGROUP_ID, GOOGLE_ANALYTICS } = RawCookieName

function extractGaClientId(gaCookieValue?: string | null): string | null {
  return gaCookieValue?.split('.').splice(2).join('-') || null
}

function useGetQueryParams(): URLSearchParams | null {
  const href = useGetHrefContext()()
  return new MaybeURL(href)?.searchParams ?? null
}

/*
N.B.!!
This algorithm for determining the ID is the same used when calculating 
userTokens for Algolia in Google Tag manager. Any changes to this code
must be reflected in GTM and vice versa!
*/

export default function useAnalyticsUserId(): string {
  const backupDepulicator = useOnce(() => uuidv4())
  const [cookies] = useCookies([SCENTREGROUP_ID, GOOGLE_ANALYTICS])
  const query = useGetQueryParams()
  const result =
    cookies[SCENTREGROUP_ID] ||
    query?.get('scg_id') ||
    extractGaClientId(cookies[GOOGLE_ANALYTICS]) ||
    `No analytics user id found (${backupDepulicator})`
  return result
}
