import { Box } from '@hub/box'
import { IconButton } from '@hub/button'
import { CrossMediumIcon } from '@hub/icon'
import { OverlayContent } from '@hub/overlay'
import { useRect } from '@hub/overlay/src/use-rect'
import { Portal } from '@hub/portal'
import React, { isValidElement, useCallback, useRef } from 'react'
import { NavigationButton } from './navigation-button'
import {
  NavigationContext,
  NavigationContextProvider,
  useNavigationContext,
} from './navigation-context'

type NavigationCustomMenuRender = (
  Content: React.FC<React.PropsWithChildren<React.ComponentProps<typeof Box>>>,
  renderProps: NavigationContext
) => React.ReactNode

type NavigationCustomMenuProps = {
  children:
    | (
        | React.ReactElement<React.ComponentProps<typeof NavigationButton>>
        | NavigationCustomMenuRender
      )
    | (
        | React.ReactElement<React.ComponentProps<typeof NavigationButton>>
        | NavigationCustomMenuRender
      )[]
  onClick?: React.MouseEventHandler
}

export const NavigationCustomMenu: React.FC<NavigationCustomMenuProps> = ({
  children,
  onClick,
}) => {
  const context = useNavigationContext()
  const { isOpen, onOpen, onClose, onMouseEnter } = context
  const buttonRef = useRef<HTMLElement>(null)
  const [buttonRect] = useRect([() => buttonRef.current, () => document.body])

  const handleClick = useCallback<React.MouseEventHandler>(
    event => {
      onClick?.(event)
      if (isOpen) {
        onClose?.()
      } else {
        onOpen?.()
      }
    },
    [isOpen, onClick, onClose, onOpen]
  )

  const handleMouseEnter = useCallback<React.MouseEventHandler>(
    event => {
      onMouseEnter?.(event)
    },
    [onMouseEnter]
  )

  const content = [children].flat().map((child, key) => {
    if (isValidElement(child)) {
      return (
        <NavigationContextProvider
          key={key}
          value={{
            buttonRef,
            onClick: handleClick,
            onMouseEnter: handleMouseEnter,
          }}
        >
          {child}
        </NavigationContextProvider>
      )
    }
    if (typeof child === 'function') {
      return (
        <Portal key={key}>
          <Box
            sx={{
              position: ['absolute', null, 'static'],
              top: 0,
              left: 0,
              minWidth: ['100vw', null, 'auto'],
              minHeight: ['100vh', null, 'auto'],
              pointerEvents: 'none',
            }}
          >
            <OverlayContent
              isOpen={isOpen ?? false}
              placement={['bottom', null, 'top']}
              sx={{}}
            >
              {OverlayContentBox => (
                <OverlayContentBox
                  sx={{
                    maxHeight: [
                      '100vh',
                      null,
                      `calc(100vh - ${
                        buttonRect?.bottom ?? 0
                      }px + ${scrollY}px)`,
                    ],
                    overflowY: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: ['flex', null, 'none'],
                      flexGrow: 0,
                      justifyContent: 'end',
                    }}
                  >
                    <IconButton
                      variant="transparent"
                      colorScheme="licorice"
                      size="icon-lg"
                      icon={<CrossMediumIcon boxSize="size-icon-md" />}
                      onClick={() => onClose?.()}
                      aria-label={'close menu'}
                    />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      overflowY: 'auto',
                      scrollBehavior: 'smooth',
                    }}
                  >
                    {child(Box, context)}
                  </Box>
                </OverlayContentBox>
              )}
            </OverlayContent>
          </Box>
        </Portal>
      )
    }
    return child
  })

  return <>{content}</>
}
