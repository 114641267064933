import React, { Children, cloneElement, isValidElement } from 'react'
import {
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbItemProps,
  BreadcrumbLinkProps,
} from '@chakra-ui/react'
import { BreadcrumbLink } from './breadcrumb-link'

function isBreadcrumbLink(
  child: React.ReactNode
): child is React.ReactElement<typeof BreadcrumbLink> {
  return isValidElement(child) && child.type === BreadcrumbLink
}

export const BreadcrumbItem: React.FC<
  Pick<BreadcrumbItemProps, 'as' | 'sx' | 'children' | 'isCurrentPage'>
> = ({ children, isCurrentPage, ...props }) => {
  const clones = Children.map(children, child => {
    if (!isValidElement(child)) {
      return child
    }

    if (isBreadcrumbLink(child)) {
      return cloneElement(child, {
        isCurrentPage,
      } as Partial<React.PropsWithChildren<BreadcrumbLinkProps>>)
    }
    return child
  })

  return (
    <ChakraBreadcrumbItem isCurrentPage={isCurrentPage} {...props}>
      {clones}
    </ChakraBreadcrumbItem>
  )
}
