import React from 'react'
import { CoreContainer } from '@hub/core-container'
import { HubStyleObject } from '@hub/design-system-base'
import { LinkBox, LinkOverlay } from '@hub/link'
import { Image } from '@hub/image'
import { makeUnscaledImageUrl } from '../cloudinary-image/helpers/make-image-url'
export { default as getTopStripProps } from './get-top-strip-props'
export { default as useTopStripProps } from './use-top-strip-props'
export type { ITopStripProps } from './types'
import { ITopStripProps } from './types'

const TopStrip: React.FC<
  React.PropsWithChildren<
    ITopStripProps & { onClick?: React.MouseEventHandler } & {
      sx?: HubStyleObject
    }
  >
> = ({
  backgroundImage,
  backgroundColour: backgroundColor,
  foregroundImage,
  shortDescription,
  actionText,
  url,
  backendTitle,
  onClick,
  sx,
}) => (
  <LinkBox onClick={onClick}>
    <CoreContainer maxWidth="size-full" gutter={[]}>
      <LinkOverlay
        href={url}
        sx={{
          display: 'block',
          backgroundColor,
          backgroundImage: backgroundImage
            ? makeUnscaledImageUrl(backgroundImage)
            : undefined,
          backgroundSize: 'auto var(--chakra-sizes-size-xs)',
          backgroundRepeat: 'repeat',
          backgroundPositionX: 'left',
        }}
        title={actionText}
      >
        <Image
          src={makeUnscaledImageUrl(foregroundImage)}
          description={shortDescription}
          sx={{
            margin: '0 auto',
            height: isOlympics(backendTitle)
              ? 120
              : 'var(--chakra-sizes-size-xs)',
            ...sx,
          }}
        />
      </LinkOverlay>
    </CoreContainer>
  </LinkBox>
)

function isOlympics(backendTitle: string): boolean {
  return (
    backendTitle.toLowerCase().indexOf('olympics') > -1 ||
    backendTitle.toLowerCase().indexOf('paralympic') > -1
  )
}

export default TopStrip
