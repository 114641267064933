import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  AutocompleteSource,
  AutocompleteCollection,
} from '@algolia/autocomplete-core'

import { Box } from '@hub/box'
import { UnorderedList, ListItem } from '@hub/list'
import { H2 } from '@hub/heading'
import { Button } from '@hub/button'
import { Link } from '@hub/link'
import { StandardColors, useToken } from '@hub/design-system-base'

import { BaseItem, Auto, RenderContext } from '../types'
import { DisplayForHitWithHiglighting } from '../get-display-name-for-hit'
import getUrlForHit from '../get-url-for-hit'
import { FilteredCentreList } from '../../../hub-components/filtered-centre-list'
import { useCountry } from '../../../country'
import { useCentres } from '../../../centres'
import type { RefsAndCursor } from './use-refs-and-cursor'

interface ResultsSectionProps {
  key: string
  source: AutocompleteSource<BaseItem>
  items: BaseItem[]
  auto: Auto
  refsAndCursor: RefsAndCursor
  itemCountSoFar: number
  context: RenderContext
}

const ResultsSection: React.FC<
  React.PropsWithChildren<ResultsSectionProps>
> = ({ source, items, auto, refsAndCursor, itemCountSoFar, context }) => {
  if (items.length === 0) {
    return null
  }
  const elems = refsAndCursor.autocompleteRefs.getElems()
  return (
    <Box>
      <H2
        sx={{
          fontSize: 'font-md',
          fontWeight: 'medium',
          lineHeight: 'base',
          color: 'textPrimary',
          marginTop: 'spacing-lg',
          textTransform: 'uppercase',
          marginBottom: 'spacing-xs',
        }}
      >
        {source.sourceId}
      </H2>
      <UnorderedList
        {...auto.getListProps(elems)}
        sx={{
          listStyle: 'none',
          margin: 'spacing-none',
          padding: 'spacing-none',
        }}
      >
        {items.map((item, i) => {
          const props = auto.getItemProps({
            item,
            source,
            ...elems,
          })
          const href = getUrlForHit(item, context)
          return (
            <ListItem
              key={i}
              {...props}
              // workaround `props` having misleading types
              onClick={props.onClick as React.MouseEventHandler}
              onMouseEnter={props.onMouseEnter as React.MouseEventHandler}
              onMouseLeave={props.onMouseLeave as React.MouseEventHandler}
              sx={{
                paddingY: 'spacing-xs',
                '&:hover, &:focus-within': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              <Link
                as="a"
                ref={refsAndCursor.registerAutocompleteElement(
                  i + itemCountSoFar
                )}
                href={href}
                onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
                  if (e.key === 'ArrowDown') {
                    refsAndCursor.moveDown()
                    e.preventDefault()
                  } else if (e.key === 'ArrowUp') {
                    refsAndCursor.moveUp()
                    e.preventDefault()
                  }
                }}
              >
                <DisplayForHitWithHiglighting hit={item} context={context} />
              </Link>
            </ListItem>
          )
        })}
      </UnorderedList>
    </Box>
  )
}

interface ResultsPanelProps {
  autocomplete: Auto
  isOpen?: boolean
  collections: AutocompleteCollection<BaseItem>[]
  refsAndCursor: RefsAndCursor
  context: RenderContext
  backgroundColor: StandardColors
  reinitialiseQuery: () => void
}

type ResultsSectionsProps = Omit<
  ResultsPanelProps,
  'isOpen' | 'backgroundColor' | 'reinitialiseQuery'
>

function collectionsAndItemsSoFar(
  collections: AutocompleteCollection<BaseItem>[]
): {
  collection: AutocompleteCollection<BaseItem>
  itemsSoFar: number
}[] {
  let itemsSoFar = 0
  return (collections ?? []).map(collection => {
    const result = { collection, itemsSoFar }
    itemsSoFar += collection.items.length
    return result
  })
}

const ResultsSections: React.FC<
  React.PropsWithChildren<ResultsSectionsProps>
> = ({ collections, refsAndCursor, autocomplete, context }) => {
  const refs = refsAndCursor.autocompleteRefs

  const [boxMaxHeight, setBoxMaxHeight] = useState('100vh')

  const calculateBoxMaxHeight = useCallback(() => {
    const offsetTop = `${refs.panelRef.current?.offsetTop ?? 0}px`
    const seeAllStyle =
      refs.seeAllRef.current && window.getComputedStyle(refs.seeAllRef.current)
    const seeAllMarginTop = seeAllStyle?.marginTop ?? '0px'
    const seeAllMarginBottom = seeAllStyle?.marginBottom ?? '0px'
    const seeAllHeight = `${refs.seeAllRef.current?.clientHeight ?? 0}px`

    const maxHeight = `calc(100vh - ${offsetTop} - ${seeAllMarginTop} - ${seeAllHeight} - ${seeAllMarginBottom})`
    setBoxMaxHeight(maxHeight)
  }, [refs.panelRef, refs.seeAllRef])

  useEffect(() => {
    calculateBoxMaxHeight()
    window.addEventListener('resize', calculateBoxMaxHeight)
    return () => window.removeEventListener('resize', calculateBoxMaxHeight)
  }, [calculateBoxMaxHeight])

  return (
    <Box
      sx={{
        maxHeight: boxMaxHeight,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      {collectionsAndItemsSoFar(collections).map(
        ({ collection, itemsSoFar }, index) => {
          const key = `source-${index}`
          return (
            <ResultsSection
              key={key}
              refsAndCursor={refsAndCursor}
              auto={autocomplete}
              itemCountSoFar={itemsSoFar}
              context={context}
              {...collection}
            />
          )
        }
      )}
    </Box>
  )
}

const ResultsPanel: React.FC<React.PropsWithChildren<ResultsPanelProps>> = ({
  autocomplete,
  collections,
  refsAndCursor,
  context,
  backgroundColor,
  reinitialiseQuery,
}) => {
  const centres = useCentres()
  const country = useCountry()
  const refs = refsAndCursor.autocompleteRefs

  const centreListRef = useRef<HTMLElement | null>(null)

  const [maxHeight, spacingLg] = useToken('sizes', ['size-27', 'size-5'])
  const [boxHeight, setBoxHeight] = useState(`${maxHeight}`)
  const calculateBoxHeight = useCallback((): void => {
    const offsetTop = centreListRef.current?.offsetTop ?? 0
    const height = `calc(min(${maxHeight}, 100vh - ${offsetTop}px - ${spacingLg}))`
    setBoxHeight(height)
  }, [maxHeight, spacingLg])

  useEffect(() => {
    calculateBoxHeight()
    window.addEventListener('resize', calculateBoxHeight)
    return () => window.removeEventListener('resize', calculateBoxHeight)
  }, [calculateBoxHeight])

  const isEmptyQueryNational =
    !context.centre && !context.query && !context.specialSearchKind
  const isSpecialCentresSearch = context.specialSearchKind === 'centres'
  if (isEmptyQueryNational || isSpecialCentresSearch) {
    return (
      <Box
        ref={centreListRef}
        sx={{
          background: backgroundColor,
          overflow: 'auto',
          height: boxHeight,
          overscrollBehavior: 'none',
          paddingBottom: 'spacing-md',
        }}
      >
        <FilteredCentreList
          heading="Find a Westfield centre"
          query={context.query}
          country={country}
          items={centres.map(centre => ({
            centre,
            label: centre.title,
            url: `/${centre.slug}`,
          }))}
        />
      </Box>
    )
  }
  const panelProps = autocomplete.getPanelProps(refs.getElems())
  const onMouseLeave = (): void => {
    reinitialiseQuery()
    panelProps?.onMouseLeave()
  }
  return (
    <Box
      ref={refs.panelRef}
      {...panelProps}
      onMouseLeave={onMouseLeave}
      sx={{
        background: backgroundColor,
        width: '100%',
      }}
    >
      <ResultsSections
        {...{
          context,
          autocomplete,
          collections,
          refsAndCursor,
        }}
      />
      {context.query ? (
        <Button
          type="submit"
          colorScheme="licorice"
          variant="solid"
          sx={{
            width: '100%',
            marginY: 'spacing-lg',
          }}
          ref={refs.seeAllRef}
          onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'ArrowUp') {
              refsAndCursor.moveUp()
              e.preventDefault()
            }
          }}
        >
          See all results
        </Button>
      ) : (
        <Box sx={{ paddingBottom: 'spacing-md' }} />
      )}
    </Box>
  )
}

export default ResultsPanel
