import { IconButton } from '@hub/button'
import { ArrowLeftMediumIcon } from '@hub/icon'
import React, { useCallback } from 'react'
import { useNavigationContext } from './navigation-context'

type NavigationPanelBackButtonProps = {
  isMobileOnly?: boolean
  onClick?: (
    event: React.MouseEvent,
    context: { isOpen: boolean | undefined }
  ) => void
}

export const NavigationPanelBackButton: React.FC<
  React.PropsWithChildren<NavigationPanelBackButtonProps>
> = ({ isMobileOnly, onClick }) => {
  const { isOpen, onClose } = useNavigationContext()

  const handleClick = useCallback<React.MouseEventHandler>(
    event => {
      onClick?.(event, { isOpen })
      if (event.isDefaultPrevented()) {
        return
      }
      onClose?.(false)
    },
    [isOpen, onClick, onClose]
  )
  return (
    <IconButton
      variant="transparent"
      colorScheme="licorice"
      size="icon-lg"
      icon={<ArrowLeftMediumIcon boxSize="size-icon-md" />}
      onClick={handleClick}
      aria-label={'close navigation panel'}
      sx={
        isMobileOnly
          ? {
              display: [null, null, 'none'],
            }
          : undefined
      }
    />
  )
}
