import { useEffect, useRef } from 'react'

const overlayMap = new Map<symbol, () => void>()
export const useSingleOverlay = (
  isOpen: boolean,
  onClose?: () => void
): void => {
  const symbol = useRef(Symbol())
  useEffect(() => {
    if (!isOpen) {
      return
    }
    const key = symbol.current
    overlayMap.set(key, () => {
      onClose?.()
    })
    const timeout = setTimeout(() => {
      for (const [key, handler] of overlayMap.entries()) {
        if (key === symbol.current) {
          continue
        }
        handler()
      }
    }, 1)
    return () => {
      clearTimeout(timeout)
      overlayMap.delete(key)
    }
  }, [isOpen, onClose])
}
