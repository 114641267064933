import { useRef, useState, useEffect } from 'react'
import type { MutableRefObject } from 'react'
import { AutocompleteCollection } from '@algolia/autocomplete-core'
import { BaseItem, Elements, RefProps } from '../types'
import useOnce from '@scentregroup/shared/hooks/use-once'

type CursorableElement =
  | HTMLInputElement
  | HTMLButtonElement
  | HTMLAnchorElement
  | null

export class AutocompleteRefs {
  public readonly inputRef
  public readonly formRef
  public readonly panelRef
  public readonly itemsRef
  public readonly seeAllRef

  constructor({ inputRef, formRef, panelRef, itemsRef, seeAllRef }: RefProps) {
    this.inputRef = inputRef
    this.formRef = formRef
    this.panelRef = panelRef
    this.itemsRef = itemsRef
    this.seeAllRef = seeAllRef
  }

  // This return type looks wrong but it seems to be what
  // Autocomplete wants
  getElems(): Elements & Record<string, unknown> {
    return {
      inputElement: this.inputRef.current,
      formElement: this.formRef.current,
      panelElement: this.panelRef.current,
      seeAllElement: this.seeAllRef.current,
      itemElements: this.itemsRef.current,
    }
  }

  getElemAtCursor(cursor: number): CursorableElement {
    const { inputElement, seeAllElement, itemElements } = this.getElems()
    if (itemElements === null) {
      return null
    } else if (cursor === -1) {
      return inputElement
    } else if (cursor === itemElements.length) {
      return seeAllElement
    } else {
      return itemElements[cursor]
    }
  }
}

export interface RefsAndCursor {
  autocompleteRefs: AutocompleteRefs
  registerAutocompleteElement: (
    position: number
  ) => (el: HTMLAnchorElement) => void
  moveUp: () => void
  moveDown: () => void
  resetCursor: () => void
}

export default function useRefsAndCursor(
  query: string | undefined,
  collections: AutocompleteCollection<BaseItem>[] | undefined
): RefsAndCursor {
  const [cursor, setCursor] = useState(-1)
  const inputRef = useRef(null)
  const formRef = useRef(null)
  const panelRef = useRef(null)
  const seeAllRef = useRef(null)
  const itemsRef: MutableRefObject<HTMLAnchorElement[]> = useRef([])
  const autocompleteRefs = useOnce(
    () =>
      new AutocompleteRefs({ inputRef, formRef, panelRef, itemsRef, seeAllRef })
  )
  const numItems = (collections ?? []).flatMap(
    collection => collection.items
  ).length
  // Resize the ref list to the current collection:
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, numItems)
  }, [query, numItems])
  const moveDown = (): void => {
    if (cursor < itemsRef.current.length) {
      autocompleteRefs.getElemAtCursor(cursor + 1)?.focus()
      setCursor(c => c + 1)
    }
  }
  const moveUp = (): void => {
    if (cursor > -1) {
      autocompleteRefs.getElemAtCursor(cursor - 1)?.focus()
      setCursor(c => c - 1)
    }
  }
  const resetCursor = (): void => {
    setCursor(-1)
  }
  const registerAutocompleteElement =
    (position: number) => (elem: HTMLAnchorElement) => {
      const { current } = itemsRef
      if (current) {
        current[position] = elem
      }
    }
  return {
    autocompleteRefs,
    moveDown,
    moveUp,
    resetCursor,
    registerAutocompleteElement,
  }
}
