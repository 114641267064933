import React, { useEffect, useState } from 'react'
import {
  As,
  HubResponsiveArray,
  HubResponsiveValue,
  HubStyleObject,
  StandardSizes,
} from '@hub/design-system-base'
import { StandardRatios } from '@hub/frame'
import { FramedMedia } from '@hub/framed-media'
import { useIsAboveFold } from '../is-above-fold-provider'
import { useBestImage } from './helpers/find-best-image'
import { ImageFormat } from './helpers/make-image-url'
import { useImageSizes, Sizes } from './helpers/use-image-sizes'
import { useSrcSet } from './helpers/use-src-set'
import {
  DeviceType,
  videoSrcFromSGPageTemplateImage,
} from './helpers/cloudinary-video-url'
import {
  SGPageTemplateImageType,
  SGPageTemplateImage,
} from '@scentregroup/shared/types/page-templates'

export * from './constants'
export type { Sizes }

export type ResizeMode = 'crop-face' | 'crop-center' | 'pad'

interface CloudinaryImageProps {
  ratio?: HubResponsiveArray<StandardRatios>

  // pass multiple images to allow the component to choose the best fit
  imageSetOrImage: SGPageTemplateImage | SGPageTemplateImage[]

  // Choose an image type for a certain breakpoint e.g. ['SQUARE', null,
  // 'WIDE'] will use the 'SQUARE' image type for mobile and
  // 'MASTER' for desktop.
  //
  // If the preferred image type is not available for the breakpoint it will
  // fallback in the order of 'SQUARE', 'WIDE' you can control the fallback
  // order by passing an array for a breakpoint:
  //
  // e.g. [['SQUARE', null, 'WIDE'], null, ['WIDE', null, 'SQUARE']]
  //
  // This will try 'SQUARE', then 'WIDE' for mobile and 'WIDE', then 'SQAURE'
  // for desktop.
  //
  // If you want to set the fallback order for all breakpoints
  // you can pass just an array for the first breakpoint:
  //
  // e.g. [['WIDE', null, 'SQUARE']] this will try 'WIDE' then 'SQUARE' for all breakpoints
  preferredImageType?: HubResponsiveArray<
    SGPageTemplateImageType | SGPageTemplateImageType[]
  >

  // if the image needs to be made to fit the ratio it will either crop it or pad it
  // default is 'crop-face'
  // 'crop-face' - will crop the image to fit the ratio using Cloudinary's face detection to choose crop position
  // 'crop-center' - will crop the image to fit the ratio using Cloudinary centering the image
  // 'pad' will pad the image with empty space to fit the ratio
  resizeMode?: HubResponsiveArray<ResizeMode>

  as?: As
  sx?: HubStyleObject
  minWidth?: HubResponsiveValue<StandardSizes>
  maxWidth?: HubResponsiveValue<StandardSizes>

  imageRef?: any // React.ElementRef<typeof ChakraImage>

  // The format of the image
  //   - defaults to f_auto
  //   - 'none' configures the image to be returned in the underlying asset format as is by omitting the format param
  imageFormat?: HubResponsiveArray<ImageFormat>

  /**
   * @required Incredibly important to have this property to avoid huge images being loaded.
   *
   * An array or object of CSS-like breakpoints to help the browser
   * choose the best image from the srcSet.
   *
   * Array or object: Specifies the expected width of the image at each breakpoint
   *
   * number: the width of a fixed width image (image is same size at all breakpoints)
   *
   * string: a custom sizes prop
   *
   * @see https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#resolution_switching_different_sizes
   */
  sizes?: Sizes
  loadingMode?: 'lazy' | 'eager'
  onClick?: () => void
}

export function CloudinaryImage({
  ratio,
  imageSetOrImage,
  preferredImageType,
  resizeMode,
  imageFormat,
  maxWidth,
  minWidth,
  sx,
  as,
  imageRef,
  sizes,
  loadingMode,
  onClick,
}: CloudinaryImageProps): JSX.Element | null {
  const [imageRatio, setImageRatio] = useState<
    HubResponsiveArray<StandardRatios>
  >(ratio || 0)
  const preferredImage = useBestImage(imageSetOrImage, preferredImageType)

  useEffect(() => {
    async function getImageOriginalRatio(): Promise<void> {
      try {
        const image = new Image()
        image.src = preferredImage.url
        await image.decode()
        if (image.naturalHeight) {
          setImageRatio(image.naturalWidth / image.naturalHeight)
        }
      } catch {
        setImageRatio(1)
      }
    }

    if (!ratio) {
      getImageOriginalRatio()
    }
  }, [ratio, preferredImage])

  const imageSrcSet = useSrcSet(
    preferredImage,
    imageRatio,
    resizeMode,
    imageFormat
  )
  const imageSizes = useImageSizes(sizes)
  const isAboveFold = useIsAboveFold()
  const defaultLoadingMode = isAboveFold ? 'eager' : 'lazy'

  const EPSILON = 0.00001
  const isWide =
    imageRatio === '21/9' ||
    (typeof imageRatio === 'number' && Math.abs(imageRatio - 21 / 9) < EPSILON)

  const videoSrc = videoSrcFromSGPageTemplateImage(
    preferredImage,
    isWide ? DeviceType.wide : DeviceType.square
  )

  return imageRatio ? (
    <FramedMedia
      ref={imageRef}
      as={as}
      ratio={imageRatio}
      minWidth={minWidth}
      maxWidth={maxWidth}
      onClick={onClick}
      sx={{ position: 'relative', isolation: 'isolate', ...sx }}
      description={preferredImage.alt ?? undefined}
      sizes={imageSizes}
      loading={loadingMode ?? defaultLoadingMode}
      imageSrcSet={imageSrcSet}
      videoSrc={videoSrc}
    />
  ) : null
}
