import { useState } from 'react'

/**
 * This hook is similar to useMemo except:
 * - It's semantically guaranteed to only ever return the
 *   same result whereas useMemo is only a performance
 *   optimisation
 * - It has no dependency array; the result is never
 *   recalculated after the first time.
 */

const useOnce = <T>(create: () => T): T => {
  const [item] = useState(create())
  return item
}

export default useOnce
