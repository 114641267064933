export const CUSTOMER_SUPPORT = 'https://support.westfield.com.au/hc/en-us'
export const COVID_INFORMATION = '/customer-updates/coronavirus'

export const WESTFIELD_DIRECT = '/' // Will be removed later
export const WESTFIELD_ONLINE = '/shop'
export const SHOP_ABOUT = '/shop/info'
export const SHOP_CLICK_COLLECT = '/shop/info/click-and-collect'
export const SHOP_DELIVERY = '/shop/info/delivery'
export const SHOP_RETURNS = '/shop/info/returns'
export const SHOP_BRANDS = '/shop/brands'
export const PRODUCT_COLLECTIONS = '/shop/collections'

export const GIFT_CARDS_DIGITAL_RETAIL =
  'http://www.westfield.com.au/shop/westfield-digital-gift-card'
export const GIFT_CARDS_PHYSICAL_RETAIL =
  'https://www.westfieldgiftcards.com.au/Online/order/order-card'
export const GIFT_CARDS_PHYSICAL_CORPORATE =
  'https://www.westfieldgiftcards.com.au/Corporate'

export const WESTFIELD_PLUS = '/westfield-plus' // Will be removed later
export const WESTFIELD_MEMBERSHIP = '/membership'

export const RESPONSIBLE_BUSINESS_REPORT =
  'https://www.scentregroup.com/asset/1lSP8YdcOF8Xcoo7HhDJyq'

export const CAREERS = 'https://www.scentregroup.com/careers'

export const BUSINESS_SOLUTIONS =
  'https://www.scentregroup.com/business-solutions'
export const RESPONSIBLE_BUSINESS =
  'https://www.scentregroup.com/sustainability'
export const PRIVACY_POLICY = '/privacy-policy'
export const TERMS_CONDITIONS = '/terms-and-conditions'
export const FAQS = 'https://support.westfield.com.au/hc/en-us'
export const SCENTRE_GROUP_HOME = 'https://www.scentregroup.com'
export const PERSONALISED_ADS = '/personalised-ads'

export const TERMS_CONDITIONS_WESTFIELD_DIRECT =
  '/terms-and-conditions#westfield-direct-terms-and-conditions' // Will be removed later
export const TERMS_CONDITIONS_WESTFIELD_ONLINE =
  '/terms-and-conditions#westfield-online-terms-and-conditions'
export const PRIVACY_POLICY_WESTFIELD_DIRECT =
  '/privacy-policy#westfield-direct-privacy-statement' // Will be removed later
export const PRIVACY_POLICY_WESTFIELD_ONLINE =
  '/privacy-policy#westfield-online-privacy-statement'

export const TERMS_CONDITIONS_WESTFIELD_PLUS =
  '/terms-and-conditions#westfield-plus-terms-and-conditions' // Will be removed later
export const TERMS_CONDITIONS_WESTFIELD_MEMBERSHIP =
  '/terms-and-conditions#westfield-membership-terms-and-conditions'
export const PRIVACY_POLICY_WESTFIELD_PLUS =
  '/privacy-policy#westfield-plus-privacy-statement' // Will be removed later
export const PRIVACY_POLICY_WESTFIELD_MEMBERSHIP =
  '/privacy-policy#membership-privacy-statement'
export const FAQS_WESTFIELD_PLUS =
  'https://support.westfield.com.au/hc/en-us/categories/360002581332-Westfield-Plus-AU' // Will be removed later
export { FAQS_WESTFIELD_MEMBERSHIP } from '../../constants/paths'
