import React, { ReactElement } from 'react'

import { Text } from '@hub/text'
import { getCentreAcknowledgement } from '@scentregroup/shared/helpers/get-centre-acknowledgement'

interface IAcknowledgementSectionProps {
  country: string
  centreSlug?: string | undefined
}

export const AcknowledgementSection = ({
  country,
  centreSlug = undefined,
}: IAcknowledgementSectionProps): ReactElement => (
  <Text align={'justify'}>{getCentreAcknowledgement(country, centreSlug)}</Text>
)
