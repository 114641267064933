import React from 'react'
import { HubStyleObject } from '@hub/design-system-base'
import TopStrip from '../top-strip'
import { useHeaderProps } from './header'

export const HeaderTopStrip: React.FC<
  React.PropsWithChildren<{ sx?: HubStyleObject }>
> = ({ sx }) => {
  const { topStripProps: props, onClickTopStrip: onClick } = useHeaderProps()
  if (!props) {
    return null
  }
  return <TopStrip {...props} sx={sx} onClick={onClick} />
}
