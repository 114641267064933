import { StandardSizes } from '@hub/design-system-base'
import React, { useCallback, useMemo } from 'react'
import { MenuLink } from './menu-link'
import { useNavigationContext } from './navigation-context'

type NavigationLinkProps = {
  href: string
  size?: StandardSizes
  tone?: 'neutral' | 'strong'
  openInNewWindow?: boolean
  icon?: React.ReactNode
  onClick?: React.MouseEventHandler
}

export const NavigationLink: React.FC<
  React.PropsWithChildren<NavigationLinkProps>
> = ({ href, children, icon, size, tone, openInNewWindow, onClick }) => {
  const { isMenu, isBar, onClickLink } = useNavigationContext()
  const variant = useMemo(() => {
    if (isMenu) {
      return 'menu'
    }
    if (isBar) {
      return 'bar'
    }
    return 'panel'
  }, [isBar, isMenu])
  const handleClick = useCallback<React.MouseEventHandler>(
    event => {
      onClick?.(event)
      onClickLink?.(event, null)
    },
    [onClick, onClickLink]
  )
  return (
    <MenuLink
      size={size}
      tone={tone}
      variant={variant}
      href={href}
      openInNewWindow={openInNewWindow}
      onClick={handleClick}
      leftIcon={icon ? <>{icon}</> : undefined}
    >
      {children}
    </MenuLink>
  )
}
